
import { useEffect } from 'react';

const AdsComponent = (props) => {
    const { dataAdSlot } = props;

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) { }
    },[]);

    return(
        <>
        <ins className="adsbygoogle"
            style={{ display: 'block', height: '90px' }}
            data-ad-layout="in-article"
            data-ad-client="ca-pub-3408209038833777"
            data-ad-slot={dataAdSlot}
            data-ad-format="auto"
            data-full-width-responsive="true">
        </ins>
        </>
    );
};

export default AdsComponent;