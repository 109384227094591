import React, { createContext, useContext, useState } from 'react';

const PendingInvitesContext = createContext();

export function usePendingInvites() {
  return useContext(PendingInvitesContext);
}

export function PendingInvitesProvider({ children }) {
  const [pendingInvites, setPendingInvites] = useState([]);

  return (
    <PendingInvitesContext.Provider value={{ pendingInvites, setPendingInvites }}>
      {children}
    </PendingInvitesContext.Provider>
  );
}
