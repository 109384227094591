import React from "react";
import {
  Box,
  Text,
  Center,
  Image,
} from "@chakra-ui/react";
import Header from "./Header";
import Actions from "./Actions";
import ReactPlayer from "react-player";
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export default function Post({ post }) {
  const { text, media, type } = post;

  const postStyles = {
    common: {
      border: "1px solid",
      borderColor: "gray.300",
      borderRadius: "lg",
      bgColor: "white",
      boxShadow: "md",
      padding: "4",
      _hover: {
        bgColor: "black.200",
        boxShadow: "lg",
      },
    },
    request: {
      bgColor: "white",
      boxShadow: "lg",
      _hover: {
        bgColor: "black.200",
        boxShadow: "lg",
      },
    },
  };

  return (
    <Box p="2" maxW="600px" textAlign="left">
      <Box {...postStyles.common} {...(type === "request" && postStyles.request)}>
        <Header post={post} />

        <Box p="2" minH="100px">
          <Text
            wordBreak="break-word"
            fontSize="lg"
            color="gray.800"
            fontWeight="normal"
            p="2"
            mb="4"
          >
            {text}
          </Text>
          {media && media.startsWith("http") && media.includes(".mp4") && (
            <Center>
              <ReactPlayer
                url={media}
                width="100%"
                height="auto"
                controls
              />
            </Center>
          )}
          {media && media.startsWith("http") && !media.includes(".mp4") && (
            <Gallery
            items={[{ original: media }]}
            showPlayButton={false}
            styles={{
              playButton: { display: 'none' }, 
            }}
          />
          )}
        </Box>

        <Actions post={post} />
      </Box>
    </Box>
  );
}
