import { doc, getDoc, query } from "firebase/firestore";
import { auth, db } from "lib/firebase";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

export function usePoints(id) {
  const q = query(doc(db, "users", id));
  const [userDoc, isLoading] = useDocumentData(q);

  let points = userDoc?.points || 0;
  let category = 'Spiritual Novice';
  let level = 1;
  let exportPoints = 0;

  const categories = [
    { name: 'Spiritual Novice', range: 1000 },
    { name: 'Community Contributor', range: 2000 },
    { name: 'Soul Seeker', range: 3000 },
    { name: 'Prayer Warrior', range: 4000 },
    { name: 'Prayer Leader', range: 5000 }
  ];

  // Find the current category and level based on points
  for (const cat of categories) {
    if (points < cat.range) {
      category = cat.name;
      level = Math.ceil((points - (cat.range - 1000)) / 200);
      if (level > 5) {
        level = 5;
      }
      break;
    }
  }

  // Calculate exportPoints based on the current level and points within the level range
  const currentCategory = categories.find(cat => cat.name === category);
  console.log('Current Category:', currentCategory);
  if (currentCategory.name === "Spiritual Novice") {
    exportPoints = (points / 1000) * 100;
    exportPoints = Math.round(exportPoints);
    console.log('Export Points:', exportPoints);
  } 
  if (currentCategory.name === "Community Contributor") {
    exportPoints = (points / 2000) * 100;
    exportPoints = Math.round(exportPoints);
    console.log('Export Points:', exportPoints);
  } 
  if (currentCategory.name === "Soul Seeker") {
    exportPoints = (points / 3000) * 100;
    exportPoints = Math.round(exportPoints);
    console.log('Export Points:', exportPoints);
  } 
  if (currentCategory.name === "Prayer Warrior") {
    exportPoints = (points / 4000) * 100;
    exportPoints = Math.round(exportPoints);
    console.log('Export Points:', exportPoints);
  } 
  if (currentCategory.name === "Prayer Leader") {
    exportPoints = (points / 5000) * 100;
    exportPoints = Math.round(exportPoints);
    console.log('Export Points:', exportPoints);
  } 
 

  

  return { points, category, level, exportPoints, isLoading };
}
