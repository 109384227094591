import React, { useRef, useState, useEffect } from 'react';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import {
  FaCommentDots,
  FaRegCommentDots,
  FaTrash,
  FaPrayingHands,
  FaShare,
  FaSmile,
  FaRegEdit,
  FaCheck,
} from "react-icons/fa";
import { useComments } from "hooks/comments";
import { useAuth } from "hooks/auth";
import { useToggleLike, useDeletePost, useEditPost, useAddReaction } from "hooks/posts";
import { Link } from "react-router-dom";
import { DASHBOARD, PROTECTED } from "lib/routes";
import { useToast } from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useNavigate } from 'react-router-dom';

export default function Actions({ post }) {
  const { id, likes, uid, type } = post;
  const { user, isLoading: userLoading } = useAuth();
  const shareLink = `${window.location.origin}${PROTECTED}/comments/${id}`;
  const toast = useToast();
  const navigate = useNavigate();

  const isLiked = likes.includes(user?.id);
  const config = {
    id,
    isLiked,
    uid: user?.id,
  };

  const { toggleLike, isLoading: likeLoading } = useToggleLike(config);
  const { deletePost, isLoading: deleteLoading } = useDeletePost(id);
  const { editPost, isLoading: editLoading } = useEditPost(id);
  const { addReaction, isLoading: reactionLoading } = useAddReaction(id);
  const { comments, isLoading: commentsLoading } = useComments(id);

  const postTypeLabel = type === "request" ? "Request" : "Post";
  const badgeColorScheme = type === "request" ? "gray" : "blue";

  const shareButtonRef = useRef(null);
  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(post.text);

  useEffect(() => {
    const fetchReactions = async () => {
      try {
        const postDocRef = doc(db, 'posts', id);
        const postDocSnapshot = await getDoc(postDocRef);
        if (postDocSnapshot.exists()) {
          const postReactions = postDocSnapshot.data().reactions || [];
          setSelectedEmojis(postReactions);
        }
      } catch (error) {
        console.error('Error fetching reactions:', error);
      }
    };

    fetchReactions();
  }, [id]);

  const emojiStyle = {
    fontSize: '24px',
    marginRight: '5px',
    background: '#4267B2',
    color: 'white',
    borderRadius: '50%',
    padding: '5px',
  };

  const handleEmojiClick = (emoji) => {
    if (!selectedEmojis.includes(emoji)) {
      setSelectedEmojis([...selectedEmojis, emoji]);
      addReaction(emoji);
    }
  };

  const handleShareButtonClick = () => {
    const tempInput = document.createElement('input');
    tempInput.value = shareLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    toast({
      title: "Copied to clipboard!",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 3000,
    });
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = () => {
    editPost(editedText);
    setIsEditing(false);
  };

  const handleCommentButtonClick = () => {
    console.log("Comment button clicked");
  };

  return (
    <Flex p="2">
      <Flex alignItems="center" color={'#4267B2'}>
        <Tooltip label={isLiked ? "Unlike" : "Like"} aria-label="like-tooltip">
          <IconButton
            onClick={toggleLike}
            isLoading={likeLoading || userLoading}
            size={type === "request" ? "lg" : "md"}
            color={"#4267B2"}
            variant="ghost"
            icon={isLiked ? <FaPrayingHands /> : <FaPrayingHands />}
            isRound
            _hover={{ bg: type === "request" ? "#f0f0f0" : "transparent" }}
          />
        </Tooltip>
        {likes.length}
      </Flex>
      <Flex alignItems="center" ml="2" color={'#4267B2'}>
        <Tooltip label="View Comments" aria-label="comments-tooltip">
          <IconButton
            as={Link}
            to={`${PROTECTED}/comments/${id}`}
            onClick={handleCommentButtonClick}
            isLoading={commentsLoading}
            size="md"
            color={"#4267B2"}
            variant="ghost"
            icon={comments?.length === 0 ? <FaRegCommentDots /> : <FaCommentDots />}
            isRound
          />
        </Tooltip>
        {comments?.length}
      </Flex>
      <Flex alignItems="center" ml="2" color={'#4267B2'}>
        <Tooltip label="Copy Share Link" aria-label="share-tooltip">
          <IconButton
            onClick={handleShareButtonClick}
            size="md"
            color={"#4267B2"}
            variant="ghost"
            icon={<FaShare />}
            isRound
          />
        </Tooltip>
      </Flex>
      <Flex alignItems="center" ml="0" color={'#4267B2'}>
        <Menu>
          <MenuButton
            as={IconButton}
            size="md"
            color={"#4267B2"}
            variant="ghost"
            icon={<FaSmile />}
            isRound
            ref={shareButtonRef}
          />
          <MenuList>
            {["😍", "👍", "😆", "😢", "😡"].map((emoji) => (
              <MenuItem key={emoji} onClick={() => handleEmojiClick(emoji)}>
                {emoji}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      {selectedEmojis.length > 0 && (
        <Flex alignItems="center" ml="2" color={'#4267B2'}>
          {selectedEmojis.map((emoji, index) => (
            <span key={index} style={emojiStyle}>
              {emoji}
            </span>
          ))}
        </Flex>
      )}
      {!userLoading && user.id === uid && (
        <>
          {!isEditing ? (
            <Tooltip label="Edit Post" aria-label="edit-tooltip">
              <IconButton
                ml="auto"
                onClick={handleEditButtonClick}
                size="md"
                color={"#4267B2"}
                variant="ghost"
                icon={<FaRegEdit />}
                isRound
              />
            </Tooltip>
          ) : (
            <Flex alignItems="center" ml="2" color={'#4267B2'}>
              <Input
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
              />
              <Tooltip label="Save Edit" aria-label="save-edit-tooltip">
                <IconButton
                  onClick={handleSaveEdit}
                  size="md"
                  color={"#4267B2"}
                  variant="ghost"
                  icon={<FaCheck />}
                  isRound
                />
              </Tooltip>
            </Flex>
          )}
          <Tooltip label="Delete Post" aria-label="delete-tooltip">
            <IconButton
              ml="0"
              onClick={deletePost}
              isLoading={deleteLoading}
              size="md"
              color={"#4267B2"}
              variant="ghost"
              icon={<FaTrash />}
              isRound
            />
          </Tooltip>
        </>
      )}
    </Flex>
  );
}
