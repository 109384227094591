import React from "react";
import { Box } from "@chakra-ui/react";
import { FaBell } from "react-icons/fa";

function NotificationBell({ showNotifications, onClick }) {
  return (
    <Box
      as={FaBell}
      onClick={onClick}
      cursor="pointer"
      color={showNotifications ? "#4267B2" : "white"}
    />
  );
}

export default NotificationBell;
