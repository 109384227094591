import React, { useState, useEffect } from "react";
import { Box, Button, Textarea, Text, Tooltip } from "@chakra-ui/react";
import isUserPremium from "stripeHelpers/isUserPremium";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import { createCheckoutSession } from "stripeHelpers/createCheckoutSession";

export default function Sidebar() {
  const [subStatus, setSubStatus] = useState(false);
  const toast = useToast();
  const { user, isLoading: authLoading } = useAuth();

  useEffect(() => {
    if (user) {
      async function fetchUserPremiumStatus() {
        try {
          const isPremium = await isUserPremium(user.id);
          setSubStatus(isPremium);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setSubStatus(false);
        }
      }
      fetchUserPremiumStatus();
    }
  }, [user]);

  const handleToastClick = () => {
    createCheckoutSession(user.id);
  };

  return (
    <Box
      px="6"
      height="80vh"
      w="100%"
      maxW="300px"
      backgroundColor="#f0f0f0"
      backgroundSize="400% 400%"
      animation="gradientMove 15s ease infinite"
      position="relative"
      display="flex"
      flexDirection="column"
    >
      <Box
        backgroundColor="#fff"
        padding="2"
        marginBottom="2"
        marginTop="8"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
        </Text>
        <Text color={"black"}>
        </Text>
        <Box backgroundColor="#f0f0f0" padding="1" marginTop="2">
        </Box>
      </Box>
      <Box
        backgroundColor="#fff"
        padding="2"
        marginBottom="2"
        marginTop="0"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
        </Text>
        <Text color={"black"}>
        </Text>
        <Box backgroundColor="#f0f0f0" padding="1" marginTop="2">
          <Text fontSize="sm" color="#666">
          </Text>
        </Box>
      </Box>

      
      <Box
        backgroundColor="#fff"
        padding="2"
        marginBottom="2"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Box
          backgroundColor="#4267B2"
          height="4px"
          width="100%"
          position="absolute"
          top="0"
          left="0"
        ></Box>
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
          News
        </Text>
        <Text color={"black"}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Text>
        <Box backgroundColor="#f0f0f0" padding="1" marginTop="2">
          <Text fontSize="sm" color="#666">
            News Provider: XYZ News
          </Text>
        </Box>
      </Box>

      <Box
        backgroundColor="#fff"
        padding="2"
        marginBottom="2"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Box
          backgroundColor="#4267B2"
          height="4px"
          width="100%"
          position="absolute"
          top="0"
          left="0"
        ></Box>
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
          Headlines
        </Text>
        <Text color={"black"}>
          Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>
        <Box backgroundColor="#f0f0f0" padding="1" marginTop="2">
          <Text fontSize="sm" color="#666">
            News Provider: ABC News
          </Text>
        </Box>
      </Box>

      <Box
        backgroundColor="white"
        padding="2"
        marginBottom="2"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Box
          backgroundColor="#4267B2"
          height="4px"
          width="100%"
          position="absolute"
          top="0"
          left="0"
        ></Box>
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
          More News
        </Text>
        <Text color={"black"}>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
        </Text>
        <Box backgroundColor="#f0f0f0" padding="1" marginTop="2">
          <Text fontSize="sm" color="#666">
            News Provider: DEF News
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
