import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { LOGIN } from "lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { emailValidate } from "utils/form-validate";
import React, { useEffect, useState } from "react";
import { auth } from "lib/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useToast } from "@chakra-ui/react";

export default function ForgotPassword() {
  useEffect(() => {
    document.title = "Forgot Password - PrayItForward";
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const toast = useToast();

  async function handleForgotPassword(data) {
    setIsLoading(true);
    try {
      await sendResetEmail(data.email);
      setIsSuccess(true);
      toast({
        title: "Email sent",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      reset();
    } catch (error) {
      toast({
        title: "Email sent",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function sendResetEmail(email) {
    return sendPasswordResetEmail(auth, email).then(() => {
      // Password reset email sent!
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", backgroundColor: "black" }}>
      <div style={{ width: "37.5%", padding: "120px", color: "white" }}>
        <Heading
          mb="4"
          size="lg"
          textAlign="center"
          bgGradient='linear(to-l, white, #4267B2)'
          bgClip='text'
          fontSize='6xl'
          fontWeight='extrabold'
        >
          Forgot Password
        </Heading>

        {isSuccess ? (
          <Text fontSize="xl" color={"white"} align="center" mt="6">
            Password reset instructions sent to your email. Please check your inbox.
          </Text>
        ) : (
          <form onSubmit={handleSubmit(handleForgotPassword)}>
            <FormControl isInvalid={errors.email} py="2">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="user@email.com"
                color={"white"}
                borderColor={"white"}
                borderStartWidth={7}
                borderStartRadius={5}
                borderEndRadius={5}
                focusBorderColor="#4267B2"
                _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
                {...register("email", emailValidate)}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              mt="4"
              type="submit"
              bgColor="white"
              color={"black"}
              size="md"
              w="full"
              isLoading={isLoading}
              loadingText="Sending Reset Email"
            >
              Send Reset Email
            </Button>
          </form>
        )}
      </div>
    </div>
  );
}
