
import { app } from "lib/firebase";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import { useAuth } from "hooks/auth";


export default async function isUserPremium(uid) {

  const firestore = getFirestore(app);

  try {

    const userDocRef = doc(firestore, "users", uid);

    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const subscriptionsCollectionRef = collection(userDocRef, "subscriptions");

      const querySnapshot = await getDocs(subscriptionsCollectionRef);

      let isUserPremium = false;

      querySnapshot.forEach((doc) => {
        const subscriptionData = doc.data();

        if (subscriptionData.role === "premium" && subscriptionData.status === "active") {
          isUserPremium = true;
        }
        else {
          isUserPremium = false;
        }
      });

      return isUserPremium;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
