import { Box, Text, Center } from "@chakra-ui/react";
import Post from "./index";
import { getRandomBibleVerse } from "content/generateVerses"; 
import React, { useEffect, useState } from "react";
import { collection, query, where } from "firebase/firestore";
import { useGetPrayer } from "content/getPrayerOfTheDay";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "lib/firebase";


export default function PostsList({ posts }) {

  const {prayer, isLoading: prayerLoad} = useGetPrayer();
  const [prayerOfTheDay, setPrayerOfTheDay] = useState('none');

  useEffect(() => {
    if (!prayerLoad && prayer) {
      setPrayerOfTheDay(prayer[0].message); 
    }
  }, [prayer, prayerLoad]);


  // Check if there are any posts
  if (!posts || posts.length === 0) {
    return (
      <Box px="4" align="center">
        <Text textAlign="center" fontSize="xl">
          No posts yet... Feeling a little lonely here.
        </Text>
      </Box>
    );
  }

  const fakePostBox = (
    <Box
      key="fake-post"
      borderWidth="1px"
      borderRadius="md"
      p="4"
      my="4"
      boxShadow="md"
      bg="white" 
      minHeight={"200px"}
      marginLeft={"120px"}
      marginRight={"120px"}
    >
      <Center>
        <Text mt="9" align="center" fontSize="3xl" fontWeight="bold" color="#4267B2">
          Prayer of the Day
        </Text>
      </Center>
      <Center>
        <Text align="center" mt="2" fontSize="lg" color="black">
          {prayerOfTheDay} 
        </Text>
      </Center>
    </Box>
  );

  const Advertisement = (
    <Box
      key="fake-post"
      borderWidth="1px"
      borderRadius="md"
      p="4"
      my="4"
      boxShadow="md"
      bg="white" 
      minHeight={"200px"}
      marginLeft={"120px"}
      marginRight={"120px"}
    >
      <Center>
        <Text mt="9" align="center" fontSize="3xl" fontWeight="bold" color="#4267B2">
          Advertisement
        </Text>
      </Center>
      <Center>
        <Text align="center" mt="2" fontSize="lg" color="black">
        </Text>
      </Center>
    </Box>
  );


  if (posts.length === 1) {
    return (
      <Box px="4" align="center">
        <Post key={posts[0].id} post={posts[0]} />
      </Box>
    );
  }

  return (
    <Box px="4" align="center">
      {posts.map((post, index) => (
        <React.Fragment key={post.id}>
          {index % 10 === 0 && Advertisement && index !== 0 ? Advertisement : null}
          {index === 2 ? fakePostBox : <Post key={post.id} post={post} />}
        </React.Fragment>
      ))}
    </Box>
  );
}