// Initialize Stripe
import { Stripe, loadStripe } from "@stripe/stripe-js";
let stripePromise = null;
const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_test_51Nb7vpEmP0Hu3VN1sWh4QhhNfgyUUAMmQTvfygXJMDNUA6ihTCYjxcn68W9KarWZelyOyd4Fez0nJc9OMky1g5M500ANObnwSo"
    );
  }
  return stripePromise;
};
export default initializeStripe;
