import React, { useState } from "react";
import { Input, InputGroup, InputRightElement, IconButton, Flex } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (query.trim() !== "") {
        navigate(`/protected/results?query=${encodeURIComponent(query)}`);
      }
  };

  return (
    <Flex>
    <InputGroup minW={"600px"}>
      <Input
        placeholder="Search..."
        value={query}
        bgColor={"white"}
        textColor={"black"}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
      />
      <InputRightElement>
        <IconButton
          aria-label="Search"
          icon={<FaSearch />}
          onClick={handleSearch}
          size="sm"
          color={"#4267B2"}
          bgColor={"white"}
          _hover={{ bgColor: "#4267B2", color: "white" }}
        />
      </InputRightElement>
    </InputGroup>
    </Flex>
  );
}

export default SearchBar;
