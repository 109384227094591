import isUserGroup from "stripeHelpers/isUserGroup";
import { Box, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useAuth } from "hooks/auth";
import  GroupOnboarding  from "./onboarding";
import GroupHome from "./home";
import LeftSidebar from "components/layout/leftSidebar";
import Sidebar from "components/layout/Sidebar";
import Navbar from "components/layout/Navbar";
import GroupsSearch from "components/search/groups";

function GroupsPage() {
  const [groupStatus, setGroupStatus] = useState(false);
  const { user, isLoading: authLoading } = useAuth();

  useEffect(() => {
    if (user) {
      async function fetchUserGroupStatus() {
        try {
          const isGroup = await isUserGroup(user.id);
          setGroupStatus(isGroup);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setGroupStatus(false);
        }
      }
      fetchUserGroupStatus();
    }
  }, [user]);



  if (groupStatus === false) {
    return (
      <>
      <Navbar />
      <GroupHome />
      <LeftSidebar />
      </>
    );
  }
  else if (groupStatus === true) {
    return (
      <>
      <Navbar />
      <GroupHome />
      <LeftSidebar />
      <GroupsSearch />
      </>
    );
  }

}

export default GroupsPage;
