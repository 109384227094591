import { app } from "lib/firebase";
import initializeStripe from "stripeHelpers/initializeStripe";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { useToast } from "@chakra-ui/react";
import LoadingScreen from "components/layout/loadingScreen";


export async function createCheckoutSession(uid) {
  const firestore = getFirestore(app);

  try {
    const userDocRef = doc(firestore, "users", uid);
    const checkoutSessionsCollectionRef = collection(userDocRef, "checkout_sessions");

    const newDoc = await addDoc(checkoutSessionsCollectionRef, {
      price: "price_1NotTWEmP0Hu3VN1lPtgr8u2",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    onSnapshot(newDoc, async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        const stripe = await initializeStripe();
        stripe.redirectToCheckout({ sessionId });
      } 
    });
  } catch (error) {
  }
}

export async function createGroupCheckoutSession(uid) {
  const firestore = getFirestore(app);

  try {

    const userDocRef = doc(firestore, "users", uid);

    const checkoutSessionsCollectionRef = collection(userDocRef, "checkout_sessions");

    const newDoc = await addDoc(checkoutSessionsCollectionRef, {
      price: "price_1NxhcxEmP0Hu3VN1QCwPWqzS",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    onSnapshot(newDoc, async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        const stripe = await initializeStripe();
        stripe.redirectToCheckout({ sessionId });
      } 
    });
  } catch (error) {
  }
}
