import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  Center,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "lib/routes";
import { createCheckoutSession, createGroupCheckoutSession } from "stripeHelpers/createCheckoutSession";
import { useAuth } from "hooks/auth";
import LoadingScreen from "components/layout/loadingScreen";
import addFreeMonth from "stripeHelpers/addFreeMonth";

export default function ChoosePlan() {
  const gradientColor = useColorModeValue("linear(to-l, #4267B2, gray, #4267B2)");
  const { user, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState(""); 

  const handleJoinTodayClick = () => {
    toast({
      title: "Thank you!",
      description: "Redirecting...",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
    createCheckoutSession(user.id);
  };

  const handleJoinGroupClick = () => {
    toast({
      title: "Thank you!",
      description: "Redirecting...",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
    createGroupCheckoutSession(user.id); 
  };

  const handleNoThanksClick = () => {
    navigate(DASHBOARD);
  };

  const handlePromoCodeSubmit = () => {
    if (promoCode === "1free") {
      toast({
        title: "Promo code applied!",
        description: "You get one free month.",
        status: "success",
        isClosable: true,
        position: "top",
      });
      createCheckoutSession(user.id);
      addFreeMonth(user.id);
    } else {
      toast({
        title: "Invalid promo code",
        description: "Please enter a valid promo code.",
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    setIsLoading(authLoading);

    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(loadingTimeout);
  }, [authLoading]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
      }}
    >
      <div
        style={{
          flex: 1,
          backgroundImage: `url('/loginBackground.jpg')`,
          backgroundSize: "cover",
          padding: "20px",
        }}
      ></div>

      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        <VStack spacing={4} align="stretch">
          <Center
            bgGradient={gradientColor}
            bgClip="text"
            fontWeight="extrabold"
            fontSize="3xl"
          >
            <Heading as="h2" size="xlg" fontSize={"6xl"}>
              Premium Features
            </Heading>
          </Center>

          <Tooltip label="Connect with like-minded individuals from around the world." placement="bottom" bg={"#4267B2"} borderRadius={"md"}>
            <Text fontSize={"4xl"}>- Post & Engage with the Community</Text>
          </Tooltip>

          <Tooltip label="Share your prayer needs with a wider audience." placement="bottom" bg={"#4267B2"} borderRadius={"md"}>
            <Text fontSize={"4xl"}>- Send Sitewide Prayer Requests</Text>
          </Tooltip>

          <Button colorScheme="blue" size="lg" onClick={handleJoinTodayClick}>
            Join Today ($1.99/month)
          </Button>
          <Button colorScheme="green" size="lg" onClick={handleJoinGroupClick}>
            Join Group Subscription ($9.99/month)
          </Button>
          <Button colorScheme="gray" size="lg" onClick={handleNoThanksClick}>
            No Thanks
          </Button>
          <FormControl id="promoCode">
              <FormLabel>Enter Promo Code:</FormLabel>
              <Input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <Button
                mt={4}
                colorScheme="blue"
                size="sm"
                onClick={handlePromoCodeSubmit}
              >
                Apply Promo Code
              </Button>
            </FormControl>
        </VStack>
      </div>
    </div>
  );
}
