import React from "react";
import { db } from "lib/firebase";
import { query } from "firebase/firestore";
import { collection, doc, where } from "firebase/firestore";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";

export function useGetPrayer() {
    const q = query(
      collection(db, "prayeroftheday"),
      where("active", "==", true),
    );
    const [prayer, isLoading, error] = useCollectionData(q);
    if (error) throw error;
  
    return { prayer, isLoading };
  }