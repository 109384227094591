import { app } from "lib/firebase";
import initializeStripe from "stripeHelpers/initializeStripe";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export async function redirectToManageSubscription(uid) {
  const firestore = getFirestore(app);

  try {
    const userDocRef = doc(firestore, "users", uid);

    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();

      const customerStripeId = userData.stripeId;

      if (customerStripeId) {
        const stripe = require('stripe')('sk_test_51Nb7vpEmP0Hu3VN1S70ChB6bFnuUNFKKyE9juGAK6EFORkFnC0o3X45x0thdmHiqtbwsIVHbo99CCdC5vNucvnzY00c7lEjR9b')

        const session = await stripe.billingPortal.sessions.create({
        customer: customerStripeId,
        return_url: 'https://prayitforward.com',
        });

        const anchor = document.createElement('a');
        anchor.href = session.url;
        anchor.target = '_blank'; 
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        anchor.click(); 
        document.body.removeChild(anchor);
      } else {
        console.error("User does not have a Stripe customer ID.");
      }
    } else {
      console.error("User document not found.");
    }
  } catch (error) {
    console.error(error);
  }
}
