import { Box } from "@chakra-ui/react"; 
import { useComments } from "hooks/comments"; 
import Comment from "./Comment"; 
import LoadingScreen from "components/layout/loadingScreen";

export default function CommentList({ post }) {
  const { id } = post;
  const { comments, isLoading } = useComments(id);

  if (isLoading) return <LoadingScreen />;

  return (
    <Box>
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </Box>
  );
}
