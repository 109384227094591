import { useToast } from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "lib/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

export function useAddPost() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addPost({ uid, text, type, media }) {
    setLoading(true);
    try {
      if (text.length > 300) {
        throw new Error("Post text exceeds 300 characters.");
      }

      if (media) {
        const imageRef = ref(storage, `postmedia/${media.name}`);
        const snapshot = await uploadBytesResumable(imageRef, media);
        const downloadURL = await getDownloadURL(snapshot.ref);
        const id = uuidv4();
        await setDoc(doc(db, "posts", id), {
          uid,
          text,
          type,
          id,
          date: Date.now(),
          likes: [],
          media: downloadURL,
        });

      const posterUID = uid;
      const posterDocRef  = doc(db, "users", posterUID);
      const posterDoc= await getDoc(posterDocRef);
      const posterData = posterDoc.data();
      const posterPoints = posterData.points;
      await updateDoc(posterDocRef, {
        points: posterPoints + 30,
      });



        toast({
          title: "Post added successfully!",
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      } else {
        const id = uuidv4();
        await setDoc(doc(db, "posts", id), {
          uid,
          text,
          type,
          id,
          date: Date.now(),
          likes: [],
          media: null,
        });

        const posterUID = uid;
      const posterDocRef  = doc(db, "users", posterUID);
      const posterDoc= await getDoc(posterDocRef);
      const posterData = posterDoc.data();
      const posterPoints = posterData.points;
      await updateDoc(posterDocRef, {
        points: posterPoints + 30,
      });

        toast({
          title: "Post added successfully!",
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error adding post:", error);
      toast({
        title: "Error Adding Post",
        description: error.message || "An error occurred while adding the post.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  return { addPost, isLoading };
}

export function useAddRequest() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addRequest(post, type) {
    setLoading(true);
    try {
      if (post.text.length > 300) {
        throw new Error("Request text exceeds 300 characters.");
      }

      const id = uuidv4();
      await setDoc(doc(db, "posts", id), {
        ...post,
        id,
        date: Date.now(),
        likes: [],
        type: "request",
      });

      const posterUID = id;
      const posterDocRef  = doc(db, "users", posterUID);
      const posterDoc= await getDoc(posterDocRef);
      const posterData = posterDoc.data();
      const posterPoints = posterData.points;
      await updateDoc(posterDocRef, {
        points: posterPoints + 30,
      });

      toast({
        title: "Prayer request successfully added!",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } catch (error) {
      console.error("Error adding request:", error);
      toast({
        title: "Error Adding Request",
        description: error.message || "An error occurred while adding the request.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  return { addRequest, isLoading };
}




export function useToggleLike({ id, isLiked, uid }) {
  const [isLoading, setLoading] = useState(false);

  async function toggleLike() {
    setLoading(true);
    const docRef = doc(db, "posts", id);
    const postDoc = await getDoc(doc(db, "posts", id));
    const postData = postDoc.data()
    const posterUID = postData.uid;
    const posterRef = doc(db, "users", posterUID);
    const message = `{${uid}} You received a like!`;


    // Start of points
    const posterDocRef  = doc(db, "users", posterUID);
    const posterDoc= await getDoc(posterDocRef);
    const posterData = posterDoc.data();
    const posterPoints = posterData.points;
    await updateDoc(posterDocRef, {
      points: posterPoints + 5,
    });

    
    await updateDoc(posterRef, {
      notifications: isLiked ? arrayRemove(message) : arrayUnion(message),
    });

    await updateDoc(docRef, {
      likes: isLiked ? arrayRemove(uid) : arrayUnion(uid),
    });
    setLoading(false);
  }

  return { toggleLike, isLoading };
}

export function useDeletePost(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function deletePost() {
    const res = window.confirm("Are you sure you want to delete this post?");

    if (res) {
      setLoading(true);

      // Delete post document
      await deleteDoc(doc(db, "posts", id));

      // Delete comments
      const q = query(collection(db, "comments"), where("postID", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => deleteDoc(doc.ref));

      toast({
        title: "Post deleted!",
        status: "info",
        isClosable: true,
        position: "top",
        duration: 5000,
      });

      setLoading(false);
    }
  }

  return { deletePost, isLoading };
}



export function usePost(id) {
  const q = doc(db, "posts", id);
  const [post, isLoading] = useDocumentData(q);

  return { post, isLoading };
}

export function usePosts(uid = null) {
  const q = uid
    ? query(
        collection(db, "posts"),
        orderBy("date", "desc"),
        where("uid", "==", uid)
      )
    : query(collection(db, "posts"), orderBy("date", "desc"));
  const [posts, isLoading, error] = useCollectionData(q);
  if (error) throw error;
  return { posts, isLoading };
}


export function useEditPost(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function editPost(newText) {
    const res = true

    if (res) {
      setLoading(true);

      try {
        await updateDoc(doc(db, 'posts', id), {
          text: newText,
        });

        toast({
          title: 'Post Edited!',
          status: 'success',
          isClosable: true,
          position: 'top',
          duration: 5000,
        });
      } catch (error) {
        console.error('Error editing post:', error);
        toast({
          title: 'Error Editing Post',
          description: 'An error occurred while editing the post.',
          status: 'error',
          isClosable: true,
          position: 'top',
          duration: 5000,
        });
      } finally {
        setLoading(false);
      }
    }
  }

  return { editPost, isLoading };
}

export function useAddReaction(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addReaction(reaction) {
    setLoading(true);

    try {
      const postDocRef = doc(db, 'posts', id);
      const postDocSnapshot = await getDoc(postDocRef);

      if (postDocSnapshot.exists()) {
        const currentReactions = postDocSnapshot.data().reactions || [];

        if (Array.isArray(currentReactions)) {
          if (!currentReactions.includes(reaction)) {
            currentReactions.push(reaction);
            await setDoc(postDocRef, { reactions: currentReactions }, { merge: true });
          } else {

          }
        } else {
          toast({
            title: 'Error Adding Reaction',
            description: 'Reactions field is not an array.',
            status: 'error',
            isClosable: true,
            position: 'top',
            duration: 5000,
          });
        }
      } else {
        toast({
          title: 'Error Adding Reaction',
          description: 'The post does not exist.',
          status: 'error',
          isClosable: true,
          position: 'top',
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Error adding reaction:', error);
      toast({
        title: 'Error Adding Reaction',
        description: 'An error occurred while adding the reaction.',
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  return { addReaction, isLoading };
}








