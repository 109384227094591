import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  styles: {
    global: {
      body: {

        background: "#f0f0f0",
        backgroundSize: "400% 400%", 
        animation: "gradientMove 15s ease infinite", 
      },
    },
    "@keyframes gradientMove": {
      "0%": {
        backgroundPosition: "0% 50%", 
      },
      "100%": {
        backgroundPosition: "100% 50%", 
      },
    },
  },
});

export default customTheme;
