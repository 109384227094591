import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { DASHBOARD, REGISTER, FORGOT_PASSWORD } from "lib/routes"; // Add a route for the password reset page
import { Link as RouterLink } from "react-router-dom";
import { useLogin } from "hooks/auth";
import { useForm } from "react-hook-form";
import { emailValidate, passwordValidate } from "utils/form-validate";
import React, { useEffect } from "react";
import { getRandomBibleVerse } from "content/generateVerses";
import { db } from "lib/firebase";

export default function Login() {
  useEffect(() => {
    document.title = "Login - PrayItForward";
  }, []);

  const randomVerse = getRandomBibleVerse();

  const { login, isLoading } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function handleLogin(data) {
    login({
      email: data.email,
      password: data.password,
      redirectTo: DASHBOARD,
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", backgroundColor: "black" }}>
      <div style={{ width: "37.5%", padding: "120px", color: "white" }}>
        <Heading
          mb="4"
          size="lg"
          textAlign="center"
          bgGradient='linear(to-l, white, #4267B2)'
          bgClip='text'
          fontSize='6xl'
          fontWeight='extrabold'
        >
          Log In
        </Heading>

        <form onSubmit={handleSubmit(handleLogin)}>
          <FormControl isInvalid={errors.email} py="2">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              color={"white"}
              borderColor={"white"}
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password} py="2">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              color={"white"}
              borderColor={"white"}
              placeholder="password123"
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
              {...register("password", passwordValidate)}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            mt="4"
            type="submit"
            bgColor="white"
            color={"black"}
            size="md"
            w="full"
            isLoading={isLoading}
            loadingText="Logging In"
          >
            Log In
          </Button>
        </form>
        <Text fontSize="xlg" color={"white"} align="center" mt="3">
          Don't have an account?{" "}
          <Link
            as={RouterLink}
            to={REGISTER}
            color="#4261B2"
            fontWeight="medium"
            textDecor="underline"
          >
            Join Today!
          </Link>{" "}
        </Text>
        <Text fontSize="xlg" color={"white"} align="center" mt="3">
          <Link
            as={RouterLink}
            to={FORGOT_PASSWORD} // Link to the password reset page
            color="#4261B2"
            fontWeight="medium"
            textDecor="underline"
          >
            Forgot Password?
          </Link>
        </Text>
      </div>
      <Text fontSize="md" color="white" mt="1" textAlign="center">
        {randomVerse}
      </Text>
    </div>
  );
}
