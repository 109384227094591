import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Input,
  List,
  ListItem,
  Spinner,
  Flex,
  Text,
  Image,
  Button,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import {
  useGroupsSearch,
  usePostsSearch,
  useUsersSearch,
} from 'hooks/algoliaSearch';
import Header from 'components/post/Header';
import Actions from 'components/post/Actions';
import Post from 'components/post';
import User from 'components/users/User';

const responseStyle = {
  common: {
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'lg',
    boxShadow: 'md',
    padding: '4',
    _hover: {
      bgColor: 'black.200',
      boxShadow: 'lg',
    },
  },
};

function Results() {
  const navigate = useNavigate();
  const location = useLocation();
  const { query: initialQuery } = new URLSearchParams(location.search);
  const { query, setQuery, results, loading } = useGroupsSearch(initialQuery);
  const { query: postQuery, setQuery: setPostQuery, results: postResults, loading: postLoading } = usePostsSearch(initialQuery);
  const { query: usersQuery, setQuery: setUsersQuery, results: usersResults, loading: usersLoading } = useUsersSearch(initialQuery);
  let maxResultsCategory = 'posts';
  let maxResultsCount = postResults.length;




  if (results.length > maxResultsCount) {
    maxResultsCategory = 'groups';
    maxResultsCount = results.length;
  }

  if (usersResults.length > maxResultsCount) {
    maxResultsCategory = 'users';
    maxResultsCount = usersResults.length;
  }

  const [selectedFilter, setSelectedFilter] = useState(maxResultsCategory);

  useEffect(() => {
    setSelectedFilter(maxResultsCategory);
    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get('query');
    if (queryParam) {
      setQuery(queryParam);
      setPostQuery(queryParam);
      setUsersQuery(queryParam);
    }
  }, [initialQuery, setQuery, location.search, setPostQuery, setUsersQuery]); 
  
  useEffect(() => {
    if (query !== initialQuery) {
      navigate(`?query=${query}`);
    }

   
  }, [query, initialQuery, navigate]);

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      navigate(`?query=${query}`);
    }
    setQuery(event.target.value);
  };

  return (
    <Flex direction="column" align="center" mt="4">
      <Input
        type="text"
        textColor={'black'}
        borderColor={'#4267B2'}
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleSearch}
        maxW="600px"
        minW="600px"
        mb="2"
      />
      <Flex mt="2">
        <Button
          m={'2'}
          bgColor={selectedFilter === 'groups' ? 'white' : '#4267B2'}
          textColor={selectedFilter === 'groups' ? '#4267B2' : 'white'}
          onClick={() => setSelectedFilter('groups')}
        >
          Groups
        </Button>
        <Button
          m={'2'}
          bgColor={selectedFilter === 'posts' ? 'white' : '#4267B2'}
          textColor={selectedFilter === 'posts' ? '#4267B2' : 'white'}
          onClick={() => setSelectedFilter('posts')}
        >
          Posts
        </Button>
        <Button
          m={'2'}
          bgColor={selectedFilter === 'users' ? 'white' : '#4267B2'}
          textColor={selectedFilter === 'users' ? '#4267B2' : 'white'}
          onClick={() => setSelectedFilter('users')}
        >
          Users
        </Button>
      </Flex>
      {loading ? (
        <Spinner size="lg" />
      ) : (
        <List>
          {selectedFilter === 'groups' && results.map((result) => (
            <ListItem key={result.objectID}>
              <Box {...responseStyle.common} minW={'600px'} maxW={'600px'} minH={'200px'} maxH={'200px'} mt={'4'}
              onClick={() => {
                navigate(`/protected/groups/${result.id}`);
              }}
              >
                <Flex align="center" ml={'20px'}>
                  <Image
                    src={result.profilePicture}
                    alt={result.name}
                    w="250px"
                    h="150px"
                    mr="4"
                  />
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" color={'#4267B2'}>
                      {result.name}
                    </Text>
                    <Text fontSize="md" color={'#4267B2'}>
                      {result.description}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </ListItem>
          ))}
          {selectedFilter === 'posts' && (postLoading ? (
            <Spinner size="lg" />
          ) : (
            postResults.map((result) => (
              <ListItem key={result.id}>
                <Post post={result} />
              </ListItem>
            ))
          ))}
          {selectedFilter === 'users' && (usersLoading ? (
            <Spinner size="lg" />
          ) : (
            usersResults.map((result) => (
              <ListItem key={result.id}>
                <User user={result}/>
              </ListItem>
            ))
          ))}
        </List>
      )}
    </Flex>
  );
}

export default Results;
