import React from "react";
import { Flex, Text, Badge, Box } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import { useUser } from "hooks/users";
import { formatDistanceToNow } from "date-fns";
import UsernameButton from "components/profile/UsernameButton";
import LoadingScreen from "components/layout/loadingScreen";

export default function Header({ post }) {
  const { uid, date, type, media } = post;
  const { user, isLoading } = useUser(uid);

  if (isLoading) return <LoadingScreen />;

  let borderColor = "gray.200";
  let backgroundColor = "#4267B2";
  let secondaryColor = "white";
  let postTypeLabel = "Post"; 

  if (type === "request") {
    borderColor = "gray.200";
    backgroundColor = "#4267B2";
    secondaryColor = "white";
    postTypeLabel = "Request"; 
  }

  return (
    <Flex
      alignItems="center"
      borderBottom={`2px solid ${borderColor}`}
      p="3"
      background="linear-gradient(45deg, #2196F3, #4CAF50)" 
      backgroundSize="400% 400%"
      animation="gradientMove 15s ease infinite"
      position="relative"
      borderRadius="lg" 
      boxShadow="md" 
    >
      <Avatar user={user} size="md" />

      <Box ml="4">
        <UsernameButton user={user} color='white' />
        <Text fontSize="sm" color={secondaryColor}>
          {formatDistanceToNow(date)} ago
        </Text>
      </Box>

      <Badge
        position="absolute"
        top="10px"
        right="10px"
        colorScheme={type === "request" ? "white" : "blue"}
        variant="solid" 
        fontSize="sm"
        borderRadius="md" 
        paddingX="2" 
        paddingY="1"
      >
        {postTypeLabel}
      </Badge>
    </Flex>
  );
}
