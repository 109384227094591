
import { app } from "lib/firebase";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import { useAuth } from "hooks/auth";

export default async function isUserGroup(uid) {
  const firestore = getFirestore(app);

  try {
    const userDocRef = doc(firestore, "users", uid);

    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const subscriptionsCollectionRef = collection(userDocRef, "subscriptions");

      const querySnapshot = await getDocs(subscriptionsCollectionRef);

      let isUserGroup = false;


      for (const doc of querySnapshot.docs) {
        const subscriptionData = doc.data();

        for (const item of subscriptionData.items) {
          if (item.price.id.includes('price_1NxhcxEmP0Hu3VN1QCwPWqzS')) {
            isUserGroup = true;
            break;
          }
        }

        if (isUserGroup) {
          break;
        }
      }
      return isUserGroup;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
