import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Divider,
  Avatar,
  useColorModeValue,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { DASHBOARD, PROTECTED } from "lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { FaBell, FaTimes } from "react-icons/fa";
import { useLogout } from "hooks/auth";
import { useAuth } from "hooks/auth";
import isUserPremium from "stripeHelpers/isUserPremium";
import { useToast } from "@chakra-ui/react";
import { createCheckoutSession } from "stripeHelpers/createCheckoutSession";
import { redirectToManageSubscription } from "stripeHelpers/manageStripeSubscription";
import LoadingScreen from "./loadingScreen";
import { getDoc, doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "lib/firebase";
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import NotificationBell from "./notificationBell";
import { usePendingInvites } from "./PendingInvitesContext";
import { useAcceptGroupViaEmail, useDeclineGroupInvite } from "hooks/groups";
import { useNavigate } from "react-router-dom";
import isUserGroup from "stripeHelpers/isUserGroup";
import SearchBar from "./searchBar";


export default function Navbar() {
  const { logout, isLoading } = useLogout();
  const { user, isLoading: authLoading } = useAuth();
  const toast = useToast();
  const [subStatus, setSubStatus] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notifications, setNotifications] = useState([]);
  const { declineGroupInvite, isLoading: declineGroupInviteLoading } = useDeclineGroupInvite();
  const { acceptGroupViaEmail, isLoading: acceptGroupViaEmailLoading } = useAcceptGroupViaEmail();
  const navigate = useNavigate();
  const [groupStatus, setGroupStatus] = useState(false);
  const { pendingInvites, setPendingInvites } = usePendingInvites();
  const [notificationsData, setNotificationsData] = useState([]);
  const [notificationsNonInteractable, setNotificationsNonInteractable] = useState([]);


  const [invitations, setInvitations] = useState([]);
  const [invitationActions, setInvitationActions] = useState({});

  const openNotificationModal = async () => {
    const data = await getPendingInvitesWithNames();
    setNotificationsData(data);
    onOpen();
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    if (user) {
      setNotificationsNonInteractable(user.notifications || []);
      async function fetchData() {
        try {
          const grabPendingInvites = user.pendingInvite || [];
          setPendingInvites(grabPendingInvites);
          const isPremium = await isUserPremium(user.id);
          setSubStatus(isPremium);
        } catch (error) {
          console.error("Error:", error);
          setPendingInvites([]); 
          setSubStatus(false);
        }
      }
      async function fetchUserGroupStatus() {
        try {
          const isGroup = await isUserGroup(user.id);
          setGroupStatus(isGroup);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setGroupStatus(false);
        }
      }
      fetchUserGroupStatus();

      fetchData();
    }
  }, [user]);


  const getGroupName = async (id) => {
    const groupDoc = await getDoc(doc(db, "groups", id));
    return groupDoc.data().name; 
  };

  const getPendingInvitesWithNames = async () => {
    const pendingInvitesWithNames = [];

    try {
      for (const inviteId of pendingInvites) {
        const groupDoc = await getDoc(doc(db, "groups", inviteId));

        if (groupDoc.exists()) {
          const groupName = groupDoc.data().name;
          pendingInvitesWithNames.push({ id: inviteId, name: groupName });
        }
      }
    } catch (error) {
      console.error("Error fetching pending invites with names:", error);
    }

    return pendingInvitesWithNames;
  };

  const getNotifications = async () => {
    const pendingInvitesWithNames = await getPendingInvitesWithNames();
    setInvitations(pendingInvitesWithNames);

    const initialActions = {};
    pendingInvitesWithNames.forEach((invite) => {
      initialActions[invite.id] = 'pending';
    });
    setInvitationActions(initialActions);
  };

  const handleInvitationAction = async (invitationId, action) => {
    console.log(`User chose action: ${action} for group ID: ${invitationId}`);

    if (action === "accept") {
      if (groupStatus === true) {
        await acceptGroupViaEmail(invitationId, user.email);
        window.location.reload();
      } else {
        toast({
          title: "You must have an active groups subscription to accept this invitation.",
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    } else {
      await declineGroupInvite(invitationId, user.email);
      window.location.reload();
    }

    setInvitationActions((prevState) => ({
      ...prevState,
      [invitationId]: action,
    }));
  };

  const profileLink = user ? `${PROTECTED}/profile/${user.id}` : "";

  let subscriptionText, subscriptionAction;

  if (subStatus === true) {
    subscriptionText = "Manage Subscription";
    subscriptionAction = () => {
      toast({
        title: "Redirecting...",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      redirectToManageSubscription(user.id);
    };
  } else {
    subscriptionText = "Upgrade to Premium";
    subscriptionAction = () => {
      toast({
        title: "Redirecting...",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      createCheckoutSession(user.id);
    };
  }

  const renderNotificationWithLinks = (notificationText) => {
    const parts = notificationText.split('{');
    if (parts.length === 1) {
      return <div>{notificationText}</div>;
    } else if (notificationText.includes('like!')) {
      const textInsideBraces = parts[1].split('}')[0];
      const messageText = parts[1].split('}')[1].trim();
      return (
        <div>
          <Link as={RouterLink} to={`/protected/profile/${textInsideBraces}`} fontSize={"lg"}>
            {messageText}
          </Link>
        </div>
      );
    } else if (notificationText.includes('comment!')) {
      const textInsideBraces = parts[1].split('}')[0];
      const messageText = parts[1].split('}')[1].trim();
      return (
        <div>
          <Link as={RouterLink} to={`/protected/comments/${textInsideBraces}`} fontSize={"lg"}>
            {messageText}
          </Link>
        </div>
      );
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    await updateDoc(doc(db, "users", user.id), {
      notifications: arrayRemove(notificationId),
    });

    const updatedNotifications = notificationsNonInteractable.filter(
      (item) => item !== notificationId
    );
    setNotificationsNonInteractable(updatedNotifications);
  }

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  if (isLoading || authLoading) return <LoadingScreen />;

  return (
    <>
      <Flex
        shadow="sm"
        pos="fixed"
        width="full"
        borderTop="6px solid"
        borderTopColor="#4267B2"
        height="16"
        zIndex="3"
        justify="center"
        bg="#4267B2"
      >
        <Flex px="0" w="full" align="center" maxW="1200px">
          <Link
            color="white"
            as={RouterLink}
            to={DASHBOARD}
            fontWeight="bold"
            fontSize="lg"
            padding="2"
            paddingRight={"5"}
            paddingLeft={"0"}
          >
            LOGO PLACEHOLDER
          </Link>

          <Center>
            <Box
              ml="auto"
              display="flex"
              alignItems="center"
              paddingLeft={"0"}
            >
              <Flex mt ="0">  
              <SearchBar onSearch={handleSearch} />
              </Flex>
              <Flex ml={"5"}>
                <NotificationBell
                  showNotifications={showNotifications}
                  onClick={async () => {
                    const notificationsData = await getPendingInvitesWithNames();
                    openNotificationModal(notificationsData);
                  }}
                />
              </Flex>
              <Modal isOpen={isOpen} onClose={onClose} size="md">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Notifications</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {notificationsData.length === 0 && notificationsNonInteractable.length === 0 ? (
                      <Text color="gray.500" textAlign="center">
                        No notifications yet.
                      </Text>
                    ) : (
                      <>
                        {notificationsData.map((notification, index) => (
                          <Box
                            key={notification.id}
                            borderWidth="1px"
                            borderColor="gray.200"
                            borderRadius="md"
                            p="4"
                            mb="4"
                            boxShadow="md"
                          >
                            {notificationsNonInteractable.includes(notification.type) ? (
                              // Render non-interactable notifications differently
                              <Text>{notification.text}</Text>
                            ) : (
                              // Render other notifications with accept and decline buttons
                              <>
                                <Text fontSize="lg" fontWeight="bold" mb="2">
                                  You have been invited to join the group "{notification.name}"
                                </Text>
                                <Flex mt="2">
                                  <Button
                                    onClick={() => handleInvitationAction(notification.id, 'accept')}
                                    colorScheme="green"
                                    ml="2"
                                    flex="1"
                                    _hover={{ bg: "green.600" }}
                                    _active={{ bg: "green.700" }}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    onClick={() => handleInvitationAction(notification.id, 'decline')}
                                    colorScheme="red"
                                    ml="2"
                                    flex="1"
                                    _hover={{ bg: "red.600" }}
                                    _active={{ bg: "red.700" }}
                                  >
                                    Decline
                                  </Button>
                                </Flex>
                              </>
                            )}
                          </Box>
                        ))}

                        {notificationsNonInteractable.map((notification, index) => (
                          <Box key={index}>
                            <Flex justifyContent="space-between" alignItems="center">
                              {renderNotificationWithLinks(notification)}
                              <IconButton
                                icon={<FaTimes />}
                                aria-label="Delete"
                                onClick={() => handleDeleteNotification(notification)}
                                colorScheme="red"
                                size="sm"
                                ml="2"
                              />
                            </Flex>
                            {index < notificationsNonInteractable.length - 1 && (
                              <Divider my={2} opacity="0.5" />
                            )}
                          </Box>
                        ))}
                      </>
                    )}
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Center>
          <Menu isLazy>
            <MenuButton
              as={Button}
              ml="2"
              color="white"
              backgroundColor="#4267B2"
              size="sm"
            >
              {user?.username}
            </MenuButton>
            <MenuList>
              <MenuItem
                as={RouterLink}
                to={profileLink}
                fontWeight="bold"
                color={"#4267B2"}
              >
                Profile
              </MenuItem>
              <MenuItem
                as={RouterLink}
                to={DASHBOARD}
                fontWeight="bold"
                color={"#4267B2"}
              >
                Settings
              </MenuItem>
              <MenuItem
                fontWeight="bold"
                onClick={subscriptionAction}
                color={"#4267B2"}
              >
                {subscriptionText}
              </MenuItem>
            </MenuList>
          </Menu>

          <Button
            ml="2"
            color="white"
            backgroundColor="#4267B2"
            size="sm"
            onClick={logout}
            isLoading={isLoading}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </>
  );
}