import { useToast } from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
  query,
  setDoc,
  where,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "lib/firebase";
import { useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuth } from "hooks/auth";
import isUserPremium from "stripeHelpers/isUserPremium";
import { useCommentPoints } from "./points";




export function useAddComment({ postID, uid }) {

  const [isLoading, setLoading] = useState(false);
  const [subStatus, setSubStatus] = useState(false);
  const { user, isLoading: authLoading } = useAuth();
  const toast = useToast();
  const [points, setPoints] = useState(0);

  async function addComment(text) {
   
      setLoading(true);
      const id = uuidv4();
      const date = Date.now();
      const docRef = doc(db, "comments", id);
      await setDoc(docRef, { text, id, postID, date, uid });
      const message = `{${postID}} You received a comment!`;

      // Get the poster and the commenters uids
      const postDoc = await getDoc(doc(db, "posts", postID));
      const postData = postDoc.data()

      // Start of points
      const posterUID = postData.uid;
      const posterDocRef  = doc(db, "users", posterUID);
      const posterDoc= await getDoc(posterDocRef);
      const posterData = posterDoc.data();
      const posterPoints = posterData.points;
      await updateDoc(posterDocRef, {
        points: posterPoints + 20,
      });
      await updateDoc(posterDocRef, {
        notifications: arrayUnion(message),
      });
      
      const commenterUID = uid;
      const commenterDocRef  = doc(db, "users", commenterUID);
      const commenterDoc= await getDoc(commenterDocRef);
      const commenterData = commenterDoc.data();
      const commenterPoints = commenterData.points;
      await updateDoc(commenterDocRef, {
        points: commenterPoints + 20,
      });


      toast({
        title: "Comment added!",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    setLoading(false);
  }

  return { addComment, isLoading };
}

export function useComments(postID) {
  const q = query(
    collection(db, "comments"),
    where("postID", "==", postID),
    orderBy("date", "asc")
  );
  const [comments, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  return { comments, isLoading };
}

export function useDeleteComment(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function deleteComment() {
    const res = window.confirm("Are you sure you want to delete this comment?");

    if (res) {
      setLoading(true);
      const docRef = doc(db, "comments", id);
      await deleteDoc(docRef);
      toast({
        title: "Comment deleted!",
        status: "info",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      setLoading(false);
    }
  }

  return { deleteComment, isLoading };
}
