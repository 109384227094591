import { Box, Text, Center } from "@chakra-ui/react";
import LeftSidebar from "components/layout/leftSidebar";

export default function Message() {


  return (
    <>
    <Box p="2" maxW="600px" textAlign="left"></Box>
    <LeftSidebar />
    </>
  );
}
