import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { DASHBOARD, LOGIN } from "lib/routes";
import { useRegister } from "hooks/auth";
import { useForm } from "react-hook-form";
import { emailValidate, passwordValidate, usernameValidate } from "utils/form-validate";

export default function Register() {
  useEffect(() => {
    document.title = "Register - PrayItForward";
  }, []);

  const { register: signup, isLoading } = useRegister();

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [emailMatch, setEmailMatch] = useState(true);

  const email = watch("email");
  const confirmEmail = watch("confirmEmail");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  useEffect(() => {
    setPasswordMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    setEmailMatch(email === confirmEmail);
  }, [email, confirmEmail]);

  async function handleRegister(data) {
    signup({
      username: data.username,
      email: data.email,
      password: data.password,
      redirectTo: DASHBOARD,
    });
  }

  const isSubmitDisabled = isLoading || !emailMatch || !passwordMatch;

  return (
    <Center
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "black",
      }}
    >
      <Box style={{ width: "37.5%", padding: "120px" }}>
        <Heading
          mb="4"
          size="lg"
          textAlign="center"
          bgGradient="linear(to-l, white, #4267B2)"
          bgClip="text"
          fontSize="6xl"
          fontWeight="extrabold"
        >
          Register
        </Heading>

        <form onSubmit={handleSubmit(handleRegister)}>
          <FormControl isInvalid={errors.username} py="2">
            <FormLabel color={"white"}>Username</FormLabel>
            <Input
              placeholder="username"
              {...formRegister("username", usernameValidate)}
              color={"white"}
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
            />
            <FormErrorMessage>
              {errors.username && errors.username.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.email} py="2">
            <FormLabel color={"white"}>Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              {...formRegister("email", emailValidate)}
              color={"white"}
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!emailMatch || errors.confirmEmail} py="2">
            <FormLabel color={"white"}>Confirm Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              {...formRegister("confirmEmail", {
                required: "Email confirmation is required",
              })}
              color={"white"}
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
              onPaste={(e) => {
                e.preventDefault(); 
              }}
            />
            <FormErrorMessage>
              {emailMatch ? null : "Emails do not match"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password} py="2">
            <FormLabel color={"white"}>Password</FormLabel>
            <Input
              type="password"
              placeholder="password123"
              {...formRegister("password", passwordValidate)}
              color={"white"}
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          {/* Password confirmation */}
          <FormControl isInvalid={!passwordMatch || errors.confirmPassword} py="2">
            <FormLabel color={"white"}>Confirm Password</FormLabel>
            <Input
              type="password"
              placeholder="password123"
              {...formRegister("confirmPassword", {
                required: "Password confirmation is required",
              })}
              color={"white"}
              borderStartWidth={7}
              borderStartRadius={5}
              borderEndRadius={5}
              focusBorderColor="#4267B2"
              _hover={{ borderStartWidth: 10, borderEndWidth: 3.5, transition: "all 0.2s" }}
            />
            <FormErrorMessage>
              {passwordMatch ? null : "Passwords do not match"}
            </FormErrorMessage>
          </FormControl>

          <Button
            mt="4"
            type="submit"
            bgColor="white"
            color={"black"}
            size="md"
            w="full"
            isLoading={isLoading}
            loadingText="Signing Up"
            isDisabled={isSubmitDisabled} // Disable the button unless the form is valid and not submitting
          >
            Register
          </Button>
        </form>

        <Text fontSize="xlg" color={"white"} align="center" mt="6">
          Already have an account?{" "}
          <Link
            as={RouterLink}
            to={LOGIN}
            color="#4267B2"
            fontWeight="medium"
            textDecor="underline"
          >
            Log In
          </Link>{" "}
          instead!
        </Text>
      </Box>
    </Center>
  );
}
