import { app } from "lib/firebase";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useAuth } from "hooks/auth";

export default async function addFreeMonth(uid) {
  const firestore = getFirestore(app);

  try {
    const userDocRef = doc(firestore, "users", uid);

    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      
}
  } catch (error) {
  }
}

async function checkUserIsPremium(userDocRef) {
  const subscriptionsCollectionRef = collection(userDocRef, "subscriptions");

  const q = query(subscriptionsCollectionRef, where("role", "==", "premium"), where("status", "==", "active"));

  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty;
}

async function addNewSubscription(userDocRef) {
  const subscriptionsCollectionRef = collection(userDocRef, "subscriptions");

  await addDoc(subscriptionsCollectionRef, {
    role: "premium",
    status: "active",
    start_date: new Date(),  
    end_date: calculateEndDate(), 
  });
}

function calculateEndDate() {
  const currentDate = new Date();
  const endDate = new Date(currentDate);
  endDate.setMonth(currentDate.getMonth() + 1); 
  return endDate;
}
