import LoadingScreen from "components/layout/loadingScreen";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "hooks/auth";
import { useAcceptGroupLink, } from "hooks/groups";
import { useUser } from "hooks/users";
import { db } from "lib/firebase";
import { DASHBOARD, GROUPS } from "lib/routes";
import { useEffect, useState } from "react";
import { unstable_HistoryRouter, useNavigate, useParams } from "react-router-dom";

function GroupInvite() {
  const { inviteID } = useParams();
  const { user } = useAuth();
  const [userId, setUserId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [email, setEmail] = useState("");
  const { acceptGroupLink, isLoading } = useAcceptGroupLink();
  const navigate = useNavigate();

  

  useEffect(() => {
    const getInfo = async () => {
      const inviteCollectionRef = collection(db, "groupInvites");
      const inviteQuery = query(inviteCollectionRef, where("id", "==", inviteID));
      const inviteQuerySnapshot = await getDocs(inviteQuery);

      setUserId(inviteQuerySnapshot.docs[0].data().userId);
      setGroupId(inviteQuerySnapshot.docs[0].data().groupId);
      setEmail(inviteQuerySnapshot.docs[0].data().email);
    };

    getInfo();

    if (user) {
        console.log(user);

      if (user.id === userId) {
        navigate(GROUPS);
        window.location.reload()
      }
      else{
        AddToGroup(groupId, user.email);
      }
    }



  }, [inviteID, user]);

  const AddToGroup = async (groupId, email) => {
    await acceptGroupLink(groupId, email);
    navigate(GROUPS);
    window.location.reload()
    
  }

  return (
    <>
    </>
  );
}

export default GroupInvite;
