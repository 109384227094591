import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Textarea,
  Switch,
  Tooltip,
  Image,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Input,
  List,
  ListItem,
  Avatar,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "lib/routes";
import LoadingScreen from "components/layout/loadingScreen";
import { useAddToGroup, useAddToGroupViaUsername, useReturnGroup, useAddToGroupViaEmail } from "hooks/groups";
import { useAuth } from "hooks/auth";
import { BsFillImageFill, BsFillPlayFill } from "react-icons/bs"; // Import relevant icons here
import PostsList from "components/post/PostsList";
import { getDoc, doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "lib/firebase";
import LeftSidebar from "components/layout/leftSidebar";
import Navbar from "components/layout/Navbar";
import { format } from "date-fns";
import GroupsSidebar from "../groupSidebar";


export default function GroupsDashboard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { group, isLoading: groupLoading } = useReturnGroup(id);
  const [isPrayerRequest, setIsPrayerRequest] = useState(false); // Initialize isPrayerRequest state
  const [postContent, setPostContent] = useState(""); // State to store post content

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupProfilePicture, setGroupProfilePicture] = useState("");

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteMethod, setInviteMethod] = useState(""); // Email, Username, or Link
  const [inviteValue, setInviteValue] = useState("");
  const [copyLinkMode, setCopyLinkMode] = useState(false);
  const { addToGroupViaUsername, isLoading: addToGroupViaUsernameLoading } = useAddToGroupViaUsername();
  const { addToGroupViaEmail, isLoading: addToGroupViaEmailLoading } = useAddToGroupViaEmail();
  


  useEffect(() => {
    if (group) {
      document.title = group[0].name + ' - PrayItForward!';

      try{
        if (!group[0].admins.includes(user.id) && !group[0].members.includes(user.id)) {
          navigate(DASHBOARD);
        }
      }
      catch{
        navigate(DASHBOARD);
      }
      

    }
  }, [group]);




  if (groupLoading) return <LoadingScreen />;

  if (!group || !user) {
    navigate(DASHBOARD);
  }


  const isAdmin = group[0].admins.includes(user.id);

  const handleTogglePrayerRequest = () => {
    setIsPrayerRequest(!isPrayerRequest);
  };

  const handlePostSubmit = (e) => {
    e.preventDefault();
    console.log("New Post Content:", postContent);
  };

  const handleInviteUsers = () => {
    setIsInviteModalOpen(true);
  };

  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
    setCopyLinkMode(false);
  };

  const handleInviteMethodChange = (method) => {
    setInviteMethod(method);
    if (method === "link") {
      setCopyLinkMode(true);
    } else {
      setCopyLinkMode(false);
    }
  };

  const handleInviteSubmit = async () => {
    console.log("Invite Method:", inviteMethod);
    console.log("Invite Value:", inviteValue);

    if (inviteMethod === "link") {
      navigator.clipboard.writeText("https://www.google.com").then(() => {
        console.log("Link copied to clipboard");
      });
    }

    if (inviteMethod === "email") {
      addToGroupViaEmail(id, inviteValue);
    }

    if (inviteMethod === "username") {
      addToGroupViaUsername(id, inviteValue);
    }

    closeInviteModal();
  };

  const handleToggleSettings = () => {
    setGroupName(group[0].name);
    setGroupDescription(group[0].description);
    setGroupProfilePicture(group[0].profilePicture);

    setSettingsModalOpen(true);
  };

  const handleSaveSettings = async () => {


    await updateDoc(doc(db, "groups", group[0].id), {
      name: groupName,
      description: groupDescription,
      profilePicture: groupProfilePicture,
    });


    setSettingsModalOpen(false);
  };

  return (
    <>
    <Flex mb={"16"}>
    <Navbar />
    </Flex>
     <Flex paddingLeft="220px" paddingRight="0" align="center" boxShadow="md" borderRadius="md" backgroundColor={"#4267B2"}>
        <Center><Flex padding="40px">
          <Avatar size="2xl" src={group[0].profilePicture} alt={group[0].name} />
        </Flex></Center>
        <Stack ml="4">
          <Text fontSize="3xl" fontWeight="bold">
            {group[0].name}
          </Text>
          <HStack spacing="4">
          <Text fontSize="lg">
              Created: {format(new Date(group[0].date), "MMM yyyy")}
            </Text>
            <Text fontSize="lg">Members: {group[0].members.length + group[0].admins.length}</Text>
          </HStack>
        </Stack>
      </Flex>

      <LeftSidebar />
      <GroupsSidebar />
    </>
  );
}