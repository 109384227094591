import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Center,
  Button,
  Heading,
  HStack,
  Textarea,
  Text,
  Flex,
  Image,
  VStack,
  Divider,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import PostsLists from "components/post/PostsList";
import { useAuth } from "hooks/auth";
import { useAddPost, useAddRequest, usePosts } from "hooks/posts";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { useAuthState } from "react-firebase-hooks/auth";
import LoadingScreen from "components/layout/loadingScreen";
import isUserPremium from "stripeHelpers/isUserPremium";
import isUserGroup from "stripeHelpers/isUserGroup";
import { useToast } from "@chakra-ui/react";
import { getRandomBibleVerse } from "content/generateVerses";
import { createCheckoutSession } from "stripeHelpers/createCheckoutSession";
import { BsFillImageFill, BsFillPlayFill } from "react-icons/bs";
import GetPrayerOfTheDay, { useGetPrayer } from "content/getPrayerOfTheDay";
import { usePoints } from "hooks/usePoints";

function NewPost() {
  const { register, handleSubmit, reset } = useForm();
  const { addPost, isLoading: addingPost } = useAddPost();
  const { user, isLoading: authLoading } = useAuth();
  const [placeholder, setPlaceholder] = useState("");
  const [subStatus, setSubStatus] = useState(false);
  const [groupStatus, setGroupStatus] = useState(false);
  const [randomVerse, setRandomVerse] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPrayerRequest, setIsPrayerRequest] = useState(false);
  const [text, setText] = useState(""); // Use text for both post and request
  const [prayerRequest, setPrayerRequest] = useState("");
  const toast = useToast();
  const fileInputRef = useRef(null);
  const { addRequest, isLoading: addingRequest } = useAddRequest();


  const handlePrayerRequestSubmit = () => {
    if (subStatus) {
      addRequest({
        uid: user.id,
        text: prayerRequest,
        type: "request",
      });
    } else {
      toast({
        title: (
          <>
            You must be a premium user to do a prayer request.{" "}
            <Button
              variant="link"
              onClick={handleToastClick}
              textDecoration="underline"
              fontSize="inherit"
              color="inherit"
              padding="0"
              margin="0"
            >
              Upgrade here!
            </Button>
          </>
        ),
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
  };

  const handleTogglePrayerRequest = () => {
    setIsPrayerRequest(!isPrayerRequest);
    setPrayerRequest("");
  };

  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleVideoSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedVideo(file);
    }
  };

  useEffect(() => {
    if (user) {
      async function fetchUserPremiumStatus() {
        try {
          const isPremium = await isUserPremium(user.id);
          setSubStatus(isPremium);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setSubStatus(false);
        }
      }
      fetchUserPremiumStatus();
    }

    if (user) {
      async function fetchUserGroupStatus() {
        try {
          const isGroup = await isUserGroup(user.id);
          setGroupStatus(isGroup);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setGroupStatus(false);
        }
      }
      fetchUserGroupStatus();
    }

    setRandomVerse(getRandomBibleVerse());

    const placeholderTexts = [
      "Share your thoughts...",
      "Express your feelings...",
      "What's on your mind?",
      "Ask a question...",
      "What are you grateful for today?",
      "Share a favorite quote...",
    ];

    const randomIndex = Math.floor(Math.random() * placeholderTexts.length);
    setPlaceholder(placeholderTexts[randomIndex]);
  }, [user]);

  const handleToastClick = () => {
    createCheckoutSession(user.id);
  };

  function handleAddPost(data) {
    if (subStatus) {
      if (isPrayerRequest) {
        handlePrayerRequestSubmit();
      } else {
        if (selectedImage || selectedVideo) {
          addPost({
            uid: user.id,
            text: text, 
            type: "post",
            media: selectedImage || selectedVideo,
          });
          setSelectedImage(null);
          setSelectedVideo(null);
        } else {
          addPost({
            uid: user.id,
            text: text,
            type: "post",
            media: null,
          });
        }
      }
    } else {
      toast({
        title: (
          <>
            You must be a premium user to post.{" "}
            <Button
              variant="link"
              onClick={handleToastClick}
              textDecoration="underline"
              fontSize="inherit"
              color="inherit"
              padding="0"
              margin="0"
            >
              Upgrade here!
            </Button>
          </>
        ),
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
    reset();

    if (authLoading || addingPost) {
      return <LoadingScreen />;
    }
  }

  return (
    <>
    <Box maxW="600px" mx="auto" py="10" color={"#f0f0f0"}>
      <form onSubmit={handleSubmit(handleAddPost)}>
        <HStack justify="space-between">
          <Heading
            bgGradient="linear(to-l, #4267B2, gray, #4267B2)"
            bgClip="text"
            fontSize="4xl"
            fontWeight="extrabold"
          >
            {isPrayerRequest ? "Prayer Request" : "New Post"}
          </Heading>
          <Button
            backgroundColor="#4267B2"
            color="white"
            type="submit"
          >
            {isPrayerRequest ? "Submit Request" : "Post"}
          </Button>
        </HStack>
        <Textarea
          as={TextareaAutosize}
          resize="none"
          mt="5"
          mb={"3"}
          color="black"
          placeholder={isPrayerRequest ? "Enter your prayer request" : placeholder}
          minRows={3}
          {...register("text", { required: true })}
          value={isPrayerRequest ? prayerRequest : text} // Use text state
          onChange={(e) => {
            if (isPrayerRequest) {
              setPrayerRequest(e.target.value);
            } else {
              setText(e.target.value);
            }
          }}
        />

        {isPrayerRequest ? null : (
          <Flex>
            <input
              type="file"
              accept="video/*,image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageSelection}
            />
            <VStack spacing={2} align="center">
              {selectedImage && (
                <Box border="5px solid #ccc" p="4" borderRadius="sm" maxW="300px">
                  <Image
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    maxW="100%"
                    maxH="200px"
                  />
                </Box>
              )}

              {selectedVideo && (
                <Box border="1px solid #ccc" p="4" borderRadius="md" maxW="300px">
                  <video controls width="100%" height="auto">
                    <source
                      src={URL.createObjectURL(selectedVideo)}
                      type={selectedVideo.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              )}
            </VStack>
          </Flex>
        )}

        <Divider my="3" />
        <Flex>
          <HStack spacing={4}>
            <label htmlFor="image-upload">
              <BsFillImageFill
                size={20}
                color={!isPrayerRequest ? "#4267B2" : "#f0f0f0"} // Change color to white when "Prayer Request" is selected
                cursor="pointer"
                style={{ pointerEvents: isPrayerRequest ? "none" : "auto" }}
              />
              <input
                type="file"
                id="image-upload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageSelection}
                disabled={isPrayerRequest}
              />
            </label>
            <label htmlFor="video-upload">
              <BsFillPlayFill
                size={20}
                color={!isPrayerRequest ? "#4267B2" : "#f0f0f0"} // Change color to white when "Prayer Request" is selected
                cursor="pointer"
                style={{ pointerEvents: isPrayerRequest ? "none" : "auto" }}
              />
              <input
                type="file"
                id="video-upload"
                accept="video/*"
                style={{ display: "none" }}
                onChange={handleVideoSelection}
                disabled={isPrayerRequest}
              />
            </label>
          </HStack>
        </Flex>
        <Center mt="4">
          <Tooltip label={isPrayerRequest ? "Toggle Post" : "Toggle Prayer Request"} placement="bottom" bg={"#4267B2"} borderRadius={"md"}>
          <Switch
            colorScheme="blue"
            isChecked={isPrayerRequest}
            onChange={handleTogglePrayerRequest}
          />
          </Tooltip>
        </Center>
      </form>
    </Box>
    </>
  );
}

function Dashboard() {
  const { posts, isLoading } = usePosts();
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    document.title = "PrayItForward!";
    setTimeout(() => {
      setIsLoadingComplete(true);
    }, 1600);
  }, []);

  if (!isLoadingComplete ) {
    return <LoadingScreen />;
  }

  return (
    <>
      <NewPost />
      <Divider my="3" />
      <PostsLists posts={posts} />
    </>
    
  );
}

export default Dashboard;
