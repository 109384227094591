import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth";
import Navbar from "components/layout/Navbar";
import Sidebar from "components/layout/Sidebar";
import { Box, Flex } from "@chakra-ui/react";
import { createCheckoutSession } from "stripeHelpers/createCheckoutSession";
import isUserPremium from "stripeHelpers/isUserPremium";
import LeftSidebar from "./leftSidebar";

import { LOGIN, DASHBOARD } from "lib/routes"; 
import LoadingScreen from "./loadingScreen";

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Gets user state
  const { user, isLoading } = useAuth();
  const [subStatus, setSubStatus] = useState(false); 

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        navigate(LOGIN);
      }

      if (user && pathname === "/") {
        try {
          navigate(DASHBOARD); 
        } catch (error) {
          navigate(LOGIN);
        }
      } else if (!user && pathname !== "/") {
        navigate(LOGIN);
      }
     
      if (!user && pathname.startsWith("/protected")) {
        navigate(LOGIN);
      }

    }
  }, [pathname, user, isLoading, navigate]);


  useEffect(() => {
    if (user) {
      async function fetchUserPremiumStatus() {
        try {
          const isPremium = await isUserPremium(user.id);
          setSubStatus(isPremium);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setSubStatus(false);
        }
      }
      fetchUserPremiumStatus();
    }
  }, [user]);

 

  return (
    <>
      <Navbar />
      <Flex
        pt="16"
        pb="12"
        mx="auto"
        w="full"
        maxW="1200px"
        color="white"
        backgroundColor="#f0f0f0"
        backgroundSize="400% 400%"
        animation="gradientMove 15s ease infinite"
      >
        <Box w="900px">
          <Outlet />
        </Box>
        
        <LeftSidebar />
        <Sidebar />
      </Flex>
    </>
  );
}
