import React from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  FaHome,
  FaInbox,
  FaBookmark,
  FaUsers,
  FaBook,
  FaSun,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { DASHBOARD } from "lib/routes";
import AdsComponent from "components/ads/AdsComponent";

const sidebarItems = [
  { icon: <FaHome />, label: "Home", path: "/protected/dashboard" },
  { icon: <FaInbox />, label: "Messages", path: "/messages" },
  { icon: <FaBookmark />, label: "Bookmarks", path: "/bookmarks" },
  { icon: <FaUsers />, label: "Groups", path: "/groups" },
  
];

export default function LeftSidebar() {
  return (
    
    <Box
      position="fixed"
      left="0"
      top="64px"
      bottom="0"
      width="230px"
      backgroundColor="#f0f0f0"
      zIndex="100"
      overflowY="auto"
    >
      <Box
        px="6"
        height="80vh"
        borderLeft="0px solid"
        borderLeftColor="#f0f0f0"
        borderRight="2px solid white"
        display="flex"
        flexDirection="column"
        paddingY="4"
      >

        {sidebarItems.map((item, index) => (
          <RouterLink key={index} to={item.path} style={{ textDecoration: "none" }}>
            <Box
              py="6"
              px="8"
              _hover={{ bg: "black.200" }}
              position="relative"
              overflow="hidden"
              textColor="#333"
              fontSize="20px"
              rounded="md"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {item.icon}
              <Text ml="2">{item.label}</Text>
            </Box>
          </RouterLink>
        ))}

      <Box
        backgroundColor="#fff"
        padding="2"
        marginBottom="2"
        marginTop="8"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
        </Text>
        <Text color={"black"}>
        </Text>
        <Box backgroundColor="#f0f0f0" padding="1" marginTop="2">
          <Text fontSize="sm" color="#666">
          </Text>
        </Box>
      </Box>
      <Box
        backgroundColor="#fff"
        padding="2"
        marginBottom="2"
        marginTop="4"
        borderRadius="md"
        boxShadow="md"
        position="relative"
      >
        <Text fontWeight="bold" color={"#4267B2"} marginBottom="2">
        </Text>
        <Text color={"black"}>
        </Text>
        <Text fontSize="sm" color="#666">Place to show ads</Text>
        <AdsComponent dataAdSlot="3408209038833777" />
      </Box>
      </Box>
    </Box>
  );
}