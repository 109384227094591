import { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';

const ALGOLIA_APP_ID = "Q5U0T546VR";
const ALGOLIA_SEARCH_KEY = "7041e76a6c54662f7b0a4f034631e33b";

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

// Initialize indices for groups, posts, and users
const groupsIndex = client.initIndex('groups');
const postsIndex = client.initIndex('posts');
const usersIndex = client.initIndex('users');

function useAlgoliaSearch(index) {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (query) {
      setLoading(true);
      client 
        .search([
          {
            indexName: index.indexName, 
            query: query,
          },
        ])
        .then(({ results }) => {
          setResults(results[0].hits);
        })
        .catch((error) => {
          console.error('Algolia search error', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setResults([]);
    }
  }, [query, index]);

  return { query, setQuery, results, loading };
}

function useGroupsSearch() {
  return useAlgoliaSearch(groupsIndex);
}

function usePostsSearch() {
  return useAlgoliaSearch(postsIndex);
}

function useUsersSearch() {
  return useAlgoliaSearch(usersIndex);
}

function useMultiSearch(queries) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    client // Call the search method on the client, not on the query
      .search(queries)
      .then(({ results }) => {
        setResults(results);
      })
      .catch((error) => {
        console.error('Algolia multi-index search error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [queries]);

  return { results, loading };
}

export { useGroupsSearch, usePostsSearch, useUsersSearch, useMultiSearch };
