import { createBrowserRouter } from "react-router-dom";
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import Layout from "components/layout";
import Dashboard from "components/dashboard";
import Comments from "components/comments";
import Profile from "components/profile";
import Users from "components/users";
import ChoosePlan from "components/auth/choosePlan";
import ForgotPassword from "components/auth/forgotPassword";
import GroupsPage from "components/groups";
import GroupCreation from "components/groups/groupCreation";
import GroupsDashboard from "components/groups/groupPages/groupDashboard";
import Message from "components/messages";
import GroupsMembers from "components/groups/groupPages/groupMembers";
import GroupsMedia from "components/groups/groupPages/groupMedia";
import GroupsEvents from "components/groups/groupPages/groupEvents";
import GroupsFundraising from "components/groups/groupPages/groupFundraising";
import GroupInvite from "components/groups/groupInvite";
import Results from "components/search";





export const ROOT = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const CHOOSE_PLAN = "/select-plan";
export const FORGOT_PASSWORD = "/forgot-password";
export const GROUPS = "/groups";
export const GROUP_CREATION = "/create-group";
export const MESSAGE = "/messages";


export const GROUP_INVITE = "/groups/invite/:inviteID";


export const PROTECTED = "/protected";
export const DASHBOARD = "/protected/dashboard";
export const USERS = "/protected/users";
export const PROFILE = "/protected/profile/:id";
export const COMMENTS = "/protected/comments/:id";
export const GROUP = "/protected/groups/:id";
export const GROUP_MEMBERS = "/protected/groups/members/:id";
export const GROUP_MEDIA = "/protected/groups/media/:id";
export const GROUP_EVENTS = "/protected/groups/events/:id";
export const GROUP_FUNDRAISING = "/protected/groups/fundraising/:id";
export const RESULTS = "/protected/results";

export const router = createBrowserRouter([
  { path: ROOT, element: <Layout />}, // Use Layout as the element for the root path
  { path: LOGIN, element: <Login /> },
  { path: REGISTER, element: <Register /> },
  { path: CHOOSE_PLAN, element: <ChoosePlan /> },
  { path: FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: GROUPS, element: <GroupsPage /> },
  { path: GROUP_CREATION, element: <GroupCreation /> },
  { path: MESSAGE, element: <Message /> },
  { path: GROUP_INVITE, element: <GroupInvite /> },



  {
    path: PROTECTED,
    element: <Layout />, // Use Layout as the element for most protected routes
    children: [
      {
        path: USERS,
        element: <Users />,
      },
      {
        path: DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: PROFILE,
        element: <Profile />,
      },
      {
        path: COMMENTS,
        element: <Comments />,
      },
      {
        path: RESULTS,
        element: <Results />,
      }
    ],
  },
  {
  path: GROUP, // Individual route for GroupsDashboard without Layout
  element: <GroupsDashboard />,
  },
   {
    path: GROUP_MEMBERS, // Individual route for GroupsDashboard without Layout
    element: <GroupsMembers />,
    },
    {
      path: GROUP_MEDIA, // Individual route for GroupsDashboard without Layout
      element: <GroupsMedia />,
    },
    {
      path: GROUP_EVENTS, // Individual route for GroupsDashboard without Layout
      element: <GroupsEvents />,
    },
    {
      path: GROUP_FUNDRAISING, // Individual route for GroupsDashboard without Layout
      element: <GroupsFundraising />,
    },
]);