import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "lib/routes";
import customTheme from "theme";
import { PendingInvitesProvider } from "components/layout/PendingInvitesContext";

export default function App() {
  return (
    <PendingInvitesProvider>
      <ChakraProvider theme={customTheme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </PendingInvitesProvider>
  );
}
