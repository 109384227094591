import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Divider,
  Heading,
  Icon,
  Flex,
  Center,
} from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import { useNavigate } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { useGroups } from "hooks/groups";
import LoadingScreen from "components/layout/loadingScreen";
import isUserGroup from "stripeHelpers/isUserGroup";
import { DASHBOARD, GROUP_CREATION } from "lib/routes";
import { createGroupCheckoutSession } from "stripeHelpers/createCheckoutSession";

function GroupHome() {
  const [groupStatus, setGroupStatus] = useState(false);
  const { user, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();

  const id = user ? user.id : null;

  const { groups, isLoading: groupsLoading } = useGroups(id);

  useEffect(() => {
    document.title = "Groups - PrayItForward!";
  }, [user]);

  useEffect(() => {
    async function fetchUserGroupStatus() {
      if (user) {
        try {
          const isGroup = await isUserGroup(user.id);
          setGroupStatus(isGroup);
        } catch (error) {
          console.error("Error checking group status:", error);
          setGroupStatus(false);
        }
      }
    }

    fetchUserGroupStatus();
  }, [user]);

  const adminGroups = [];
  const memberGroups = [];

  if (groups) {
    groups.forEach((group) => {
      const isAdmin = group.admins.includes(user.id);
      const isMember = group.members.includes(user.id);
      if (isAdmin) {
        adminGroups.push(group);
      }
      if (isMember) {
        memberGroups.push(group);
      }
    });
  }

  const handleUpgradeClick = () => {
    createGroupCheckoutSession(user.id);
  }

  if (authLoading || groupsLoading) {
    return <LoadingScreen />;
  }

  return (
    <VStack spacing={4} align="center">
      <Heading as="h1" fontSize="4xl">
        Group Home
      </Heading>
      <Divider w="50%" />
      <Box>
        <Text fontWeight="bold">Groups You Admin:</Text>
        {groupStatus ? (
          adminGroups.length > 0 ? (
            adminGroups.map((group) => (
              <Box
                key={group.id}
                p={4}
                bg="#F7F7F7"
                borderRadius="lg"
                mt={4}
                w="100%"
                textAlign="center"
                cursor="pointer"
                _hover={{ bg: "#E5E5E5" }}
                onClick={() => navigate(`/protected/groups/${group.id}`)}
              >
                <Center>
                  <Flex align="center">
                    <Text fontSize="lg" fontWeight="bold" color="#4267B2">
                      {group.name}
                    </Text>
                  </Flex>
                </Center>
                <Center>
                  <Flex align="center">
                    <Text fontSize="md" color="#666666">
                      {group.description}
                    </Text>
                  </Flex>
                </Center>
              </Box>
            ))
          ) : (
            <Text textAlign={"center"}>No groups yet...</Text>
          )
        ) : (
          <Text textAlign={"center"} fontSize="lg" color="#FF0000" fontWeight="bold">
            Upgrade to access group admin permissions
          </Text>
        )}
      </Box>
      <Divider w="50%" />
      <Box>
        <Text fontWeight="bold">Groups You Are A Member Of:</Text>
        {memberGroups.length > 0 ? (
          memberGroups.map((group) => (
            <Box
              key={group.id}
              p={4}
              bg="#F7F7F7"
              borderRadius="lg"
              mt={4}
              w="100%"
              textAlign="center"
              cursor="pointer"
              _hover={{ bg: "#E5E5E5" }}
              onClick={() => navigate(`/protected/groups/${group.id}`)}
            >
              <Center>
                <Flex align="center">
                  <Text fontSize="lg" fontWeight="bold" color="#4267B2">
                    {group.name}
                  </Text>
                </Flex>
              </Center>
              <Center>
                <Flex>
                  <Text fontSize="md" color="#666666">
                    {group.description}
                  </Text>
                </Flex>
              </Center>
            </Box>
          ))
        ) : (
          <Text textAlign={"center"}>No groups yet...</Text>
        )}
      </Box>
      <Divider w="50%" />
      <Box
        p={4}
        bg="#F7F7F7"
        borderRadius="lg"
        mt={4}
        w="100%"
        textAlign="center"
        cursor="pointer"
        _hover={{ bg: "#E5E5E5" }}
        onClick={() => {
          if (groupStatus) {
            navigate(GROUP_CREATION);
          } else {
            handleUpgradeClick();
          }
        }}
      >
        {groupStatus ? (
          <>
            <Icon as={FaUsers} boxSize={8} color="#4267B2" />
            <Text fontSize="lg" fontWeight="bold" color="#4267B2">
              Create a Group
            </Text>
            <Text fontSize="md" color="#666666">
              Start your own prayer group and invite others to join.
            </Text>
          </>
        ) : (
          <Text fontSize="lg" fontWeight="bold" color="#FF0000">
            Upgrade to access group admin permissions
          </Text>
        )}
      </Box>
    </VStack>
  );
}

export default GroupHome;
