import {
  Button,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  Center,
  Progress,
} from "@chakra-ui/react";
import PostsList from "components/post/PostsList";
import { usePosts } from "hooks/posts";
import { useUser } from "hooks/users";
import { useParams } from "react-router-dom";
import Avatar from "./Avatar";
import { format } from "date-fns";
import EditProfile from "./EditProfile";
import { useAuth } from "hooks/auth";
import LoadingScreen from "components/layout/loadingScreen";
import isUserPremium from "stripeHelpers/isUserPremium";
import React, { useEffect, useState } from "react";
import { usePoints } from "hooks/usePoints";

export default function Profile() {
  const { id } = useParams();
  const { posts, isLoading: postsLoading } = usePosts(id);
  const { user, isLoading: userLoading } = useUser(id);
  const { user: authUser, isLoading: authLoading } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [subStatus, setSubStatus] = useState(false);
  const {points, category, level, exportPoints, isLoading: pointsLoading } = usePoints(id);

  useEffect(() => {
    if (user) {
      async function fetchUserPremiumStatus() {
        try {
          const isPremium = await isUserPremium(user.id);
          setSubStatus(isPremium);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setSubStatus(false);
        }
      }
      fetchUserPremiumStatus();

    }
  }, [user]);

  if (userLoading) return <LoadingScreen />;

  return (
    <Stack spacing="5">
      <Flex paddingLeft="160px" paddingRight="0" align="center" boxShadow="md" borderRadius="md" backgroundColor={"#4267B2"}>
        <Center><Flex padding="40px">
          <Avatar size="2xl" user={user} />
        </Flex></Center>
       

        {!authLoading && authUser.id === user.id && (
          <Button
            pos="absolute"
            mb="2"
            top="6"
            right="6"
            colorScheme="blue"
            onClick={onOpen}
          >
            Edit Profile
          </Button>
        )}
        <Stack ml="4">
          <Text fontSize="3xl" fontWeight="bold">
            {user.username}
          </Text>
          <HStack spacing="4">
            <Text fontSize="lg">Posts: {posts.length}</Text>
            <Text fontSize="lg">
              Joined: {format(new Date(user.date), "MMM yyyy")}
            </Text>
            <Text fontSize="lg" color={subStatus ? "green.500" : "red.500"}>
              {subStatus ? "Premium" : "Free"}
            </Text>
          </HStack>
          <Progress value={exportPoints} max={100} /> 
          <Text fontSize="lg">{category} Level {level}</Text>
        </Stack>
      </Flex>
      <Divider my="" />
      {postsLoading ? (
        <Text>Posts are loading...</Text>
      ) : (
        <PostsList posts={posts} />
      )}
      <EditProfile isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
}