import React, { useState, useEffect } from 'react';
import { Button, Flex, Link, Input, InputGroup, InputRightElement, Spinner } from "@chakra-ui/react";
import { DASHBOARD, PROFILE } from "lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { FaSearch } from "react-icons/fa"; // Import the search icon
import { useLogout } from "hooks/auth";
import { PROTECTED, USERS } from "lib/routes";
import { useAuth } from "hooks/auth";

export default function LoadingScreen() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <div>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" color='#4267B2' />
        </Flex>
      ) : (
        <div>
          <Flex>
          </Flex>
        </div>
      )}
    </div>
  );
}
