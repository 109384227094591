import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth";
import isUserGroup from "stripeHelpers/isUserGroup";
import {
  Box,
  Text,
  Button,
  VStack,
  Divider,
  Heading,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Image,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { FaCamera, FaStar } from "react-icons/fa"; // Import icons
import LoadingScreen from "components/layout/loadingScreen";
import { DASHBOARD, GROUPS } from "lib/routes";
import { useCreateGroup } from "hooks/groups";

function GroupCreation() {
  const [groupStatus, setGroupStatus] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(true);
  const { createGroup, isLoading: creatingGroup } = useCreateGroup();

  const { user, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Group Creation - PrayItForward!";

    async function fetchUserGroupStatus() {
      try {
        const isGroup = await isUserGroup(user.id);
        setGroupStatus(isGroup);
      } catch (error) {
        console.error("Error checking group status:", error);
        setGroupStatus(false);
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      fetchUserGroupStatus();
    }
  }, [user]);

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleGroupDescriptionChange = (e) => {
    setGroupDescription(e.target.value);
  };

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleSubmit = () => {
    createGroup(user, groupName, groupDescription, profilePicture); 
  };
  

  if (loading) {
    return <LoadingScreen />;
  }

  if (groupStatus) {
    return (
      <VStack spacing={4} align="center" p={4} >
        <Heading as="h1" bgGradient='linear(to-l, #4267B2, gray, #4267B2)'
        bgClip='text'
        fontSize='6xl'
        fontWeight='extrabold'>
          Create a Group
        </Heading>
        <Divider w="50%" />
        <Box
          p={4}
          bg="gray.100"
          borderRadius="lg"
          mt={4}
          w="100%"
          textAlign="center"
          cursor="pointer"
        >
          <FormControl>
            <FormLabel fontSize="lg" fontWeight="bold" color="blue.500">
              Group Name
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter group name"
              value={groupName}
              onChange={handleGroupNameChange}
            />
          </FormControl>
        </Box>
        <Box
          p={4}
          bg="gray.100"
          borderRadius="lg"
          mt={4}
          w="100%"
          textAlign="center"
          cursor="pointer"
        >
          <FormControl>
            <FormLabel fontSize="lg" fontWeight="bold" color="blue.500">
              Group Description
            </FormLabel>
            <Textarea
              placeholder="Enter group description"
              value={groupDescription}
              onChange={handleGroupDescriptionChange}
            />
          </FormControl>
        </Box>
        <Box
          p={4}
          bg="gray.100"
          borderRadius="lg"
          mt={4}
          w="100%"
          textAlign="center"
          cursor="pointer"
        >
          <FormControl>
            <FormLabel fontSize="lg" fontWeight="bold" color="blue.500">
              Profile Picture
            </FormLabel>
            <label htmlFor="profile-picture" style={{ cursor: "pointer" }}>
              <Icon as={FaCamera} boxSize={10} color="blue.500" />
              <input
                type="file"
                id="profile-picture"
                accept="image/*"
                onChange={handleProfilePictureChange}
                style={{ display: "none" }}
              />
            </label>
            {profilePicture && (
              <Image
                src={URL.createObjectURL(profilePicture)}
                alt="Profile Preview"
                mt={2}
                maxH="150px"
              />
            )}
          </FormControl>
        </Box>
        <Button
          colorScheme="blue"
          size="lg"
          mt={4}
          onClick={handleSubmit}
          isDisabled={!groupName || !groupDescription || !profilePicture}
        >
          Create Group
        </Button>
      </VStack>
    );
  } else {
    navigate(DASHBOARD);
    return null; 
  }
}

export default GroupCreation;
