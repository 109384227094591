import React, { useEffect } from "react";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import Avatar from "components/profile/Avatar";
import UsernameButton from "components/profile/UsernameButton";
import { formatDistanceToNow } from "date-fns";
import { useAuth } from "hooks/auth";
import { useDeleteComment } from "hooks/comments";
import { useUser } from "hooks/users";
import { FaTrash } from "react-icons/fa";
import LoadingScreen from "components/layout/loadingScreen";

export default function Comment({ comment }) {
  const { text, uid, date, id } = comment;
  const { deleteComment, isLoading: deleteLoading } = useDeleteComment(id);
  const { user, isLoading: userLoading } = useUser(id);
  const { user: authUser, isLoading: authLoading } = useAuth();

  useEffect(() => {
 
  }, [user]);

  return (
    <Box px="4" py="2" maxW="600px" mx="auto" textAlign="left">
      {userLoading ? (
        <LoadingScreen />
      ) : (
        <Flex pb="2">
          {user ? (
            <Avatar user={user} size="sm" />
          ) : null}
          <Box flex="1" ml="4">
            <Flex borderBottom="1px solid" borderColor="white" pb="2">
              <Box>
                {user ? (
                  <UsernameButton user={user} colorScheme="black" />
                ) : null}
                <Text fontSize="xs" color="black">
                  {formatDistanceToNow(date)} ago
                </Text>
              </Box>
              {!authLoading && authUser.id === uid && (
                <IconButton
                  size="sm"
                  ml="auto"
                  icon={<FaTrash />}
                  colorScheme="red"
                  variant="ghost"
                  isRound
                  onClick={deleteComment}
                  isLoading={deleteLoading}
                />
              )}
            </Flex>
            <Box pt="2" fontSize="sm" color={"black"}>
              <Text>{text}</Text>
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
  );
}
