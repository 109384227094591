import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";



const STRIPE_PUBLISHABLE_KEY = "pk_test_51Nb7vpEmP0Hu3VN1sWh4QhhNfgyUUAMmQTvfygXJMDNUA6ihTCYjxcn68W9KarWZelyOyd4Fez0nJc9OMky1g5M500ANObnwSo";

const firebaseConfig = {
  apiKey: "AIzaSyBwE9gjKLUcg1aJImXN5H1xeEsJt3-F0oc",
  authDomain: "prayitforward-828ad.firebaseapp.com",
  projectId: "prayitforward-828ad",
  storageBucket: "prayitforward-828ad.appspot.com",
  messagingSenderId: "1033597477577",
  appId: "1:1033597477577:web:4dbd1bab28b9531f7f6318",
  measurementID: 'G-BR7VYS3TKQ'
};


export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export default firebase;

