import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Link as RouterLink,
  Modal,
  Input,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { FaUser, FaBook, FaImage, FaCalendarAlt, FaDollarSign, FaUsers, FaCog, FaUserPlus } from "react-icons/fa"; // Added FaUserPlus
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DASHBOARD, GROUP, GROUP_EVENTS, GROUP_FUNDRAISING, GROUP_MEDIA, GROUP_MEMBERS } from "lib/routes";
import { useAddToGroupViaEmail, useAddToGroupViaUsername, useCreateGroupInvite, useReturnGroup } from "hooks/groups";
import LoadingScreen from "components/layout/loadingScreen";
import { useAuth } from "hooks/auth";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { uuidv4 } from "@firebase/util";
import isUserPremium from "stripeHelpers/isUserPremium";

export default function GroupsSidebar() {
  const [selectedItem, setSelectedItem] = useState("Posts");
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupProfilePicture, setGroupProfilePicture] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteUsername, setInviteUsername] = useState("");
  const [inviteLink, setInviteLink] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { group, isLoading: groupLoading } = useReturnGroup(id);
  const { createGroupInvite, isLoading: inviteLoading } = useCreateGroupInvite();
  const location = useLocation();
  const { user } = useAuth();
  const toast = useToast();
  const userId = user?.id;
  const isAdmin = group?.[0].admins.includes(userId);
  const {addToGroupViaUsername, isLoading: addToGroupViaUsernameLoading} = useAddToGroupViaUsername();
  const {addToGroupViaEmail, isLoading: addToGroupViaEmailLoading} = useAddToGroupViaEmail();
  const [subStatus, setSubStatus] = useState(false);
  

  useEffect(() => {
    if (group) {
      setGroupName(group[0].name);
      const pathAfterGroups = location.pathname.split("/groups/")[1].split("/")[0];
      if (pathAfterGroups === "members") {
        setSelectedItem("Members");
      } else if (pathAfterGroups === "media") {
        setSelectedItem("Media");
      } else if (pathAfterGroups === "events") {
        setSelectedItem("Events");
      } else if (pathAfterGroups === "fundraising") {
        setSelectedItem("Fundraising");
      } else {
        setSelectedItem("Posts");
      }
    }
    if (user && group) {
      console.log(group[0].members.includes(userId), group[0].admins.includes(userId), isAdmin);
      if (group[0].members.includes(userId) || group[0].admins.includes(userId) || isAdmin) {
        setSelectedItem("Posts");
      } else {
        navigate(DASHBOARD);
      }
    }

    if (user) {
      async function fetchUserPremiumStatus() {
        try {
          const isPremium = await isUserPremium(user.id);
          setSubStatus(isPremium);
        } catch (error) {
          console.error("Error checking premium status:", error);
          setSubStatus(false);
        }
      }
      fetchUserPremiumStatus();
    }
  }, [group, location]);

  if (groupLoading) return <LoadingScreen />;


  const handleItemClick = (item) => {
    setSelectedItem(item.label);
    switch (item.label) {
      case "Members":
        navigate(GROUP_MEMBERS.replace(":id", id));
        break;
      case "Posts":
        navigate(GROUP.replace(":id", id));
        break;
      case "Media":
        navigate(GROUP_MEDIA.replace(":id", id));
        break;
      case "Events":
        navigate(GROUP_EVENTS.replace(":id", id));
        break;
      case "Fundraising":
        navigate(GROUP_FUNDRAISING.replace(":id", id));
        break;
      case "About Us":
        break;
      case "Settings":
        if (isAdmin) {
          handleSettingsClick();
        }
        break;
      case "Invite":
        handleInviteClick();
        break;
      default:
        break;
    }
  };

  const handleSettingsClick = () => {
    setIsSettingsModalOpen(true);
    setGroupName(group[0].name);
    setGroupDescription(group[0].description);
    setGroupProfilePicture(group[0].profilePicture);
  };

  const handleInviteClick = () => {
    setIsInviteModalOpen(true);
  };

  const handleUpdateGroup = async () => {
    try {
      await updateDoc(doc(db, "groups", group[0].id), {
        name: groupName,
        description: groupDescription,
        profilePicture: groupProfilePicture,
      });

      toast({
        title: "Group Updated",
        description: "Your group settings have been updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsSettingsModalOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while updating the group settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
  }

  const handleInviteLinkClick = async () => {

    const inviteID = uuidv4();

    console.log('here');
    console.log(user);

    await createGroupInvite(user, group[0].id, inviteID);

    const inviteLink = `${window.location.origin}/groups/invite/${inviteID}`;

    navigator.clipboard.writeText(inviteLink);
    toast({
      title: "Link Copied",
      description: "Invite link copied to clipboard.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }

  const sidebarItems = [
    { icon: <FaUser />, label: "About Us" },
    { icon: <FaBook />, label: "Posts" },
    { icon: <FaImage />, label: "Media" },
    { icon: <FaCalendarAlt />, label: "Events" },
    { icon: <FaDollarSign />, label: "Fundraising" },
    { icon: <FaUsers />, label: "Members" },
    isAdmin ? { icon: <FaCog />, label: "Settings" } : null,
    { icon: <FaUserPlus />, label: "Invite" },
  ].filter(Boolean);



  return (
    <Box
      position="fixed"
      right="0"
      top="64px"
      bottom="0"
      width="230px"
      backgroundColor="#f0f0f0"
      zIndex="100"
      overflowY="auto"
    >
      <Box
        px="6"
        height="80vh"
        borderLeft="0px solid"
        borderLeftColor="#f0f0f0"
        borderRight="2px solid white"
        display="flex"
        flexDirection="column"
        paddingY="4"
      >
        {sidebarItems.map((item, index) => (
          <RouterLink key={index} to={`/${item.route}`} style={{ textDecoration: "none" }}>
            <Box
              py="6"
              px="8"
              _hover={{ bg: "black.200" }}
              bgColor={
                selectedItem === item.label && item.label !== "About Us" && item.label !== "Settings" && item.label !== "Invite"
                  ? "#4267B2"
                  : "transparent"
              }
              position="relative"
              overflow="hidden"
              textColor="#333"
              fontSize="20px"
              rounded="md"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              onClick={() => {
                if (item.label === "Settings") {
                  handleSettingsClick();
                } else {
                  handleItemClick(item);
                }
              }}
            >
              {item.icon}
              <Text ml="2" mb={"1"}>
                {item.label}
              </Text>
            </Box>
            {index < sidebarItems.length - 1 && (
              <Box borderTop="1px solid rgba(0, 0, 0, 0.1)" width="80%" mx="auto"></Box>
            )}
          </RouterLink>
        ))}
      </Box>
      <Modal isOpen={isSettingsModalOpen} onClose={handleCloseSettingsModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Group Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <Input
              placeholder="Description"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleUpdateGroup}>
              Save
            </Button>
            <Button colorScheme="gray" onClick={handleCloseSettingsModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isInviteModalOpen} onClose={handleCloseInviteModal} size="md" >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            mb="4"
          />
          <Button
            colorScheme="blue"
            onClick={() => {
              addToGroupViaEmail(group[0].id, inviteEmail);
            }}
          >
            Invite by Email
          </Button>
          <Input
            placeholder="Username"
            value={inviteUsername}
            onChange={(e) => setInviteUsername(e.target.value)}
            mt="4"
          />
          <Button
            colorScheme="blue"
            onClick={() => {
              addToGroupViaUsername(group[0].id, inviteUsername);
            }}
          >
            Invite by Username
          </Button>
          <Box mt="15" textAlign="center">
            <Button
              colorScheme="blue"
              onClick={() => {
                handleInviteLinkClick();
              }}
              size="lg" 
              fontWeight="bold" 
              _hover={{
                backgroundColor: "blue.500", 
              }}
            >
              Copy Invite Link
            </Button>
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
    </Box>
  );
}
