import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Input,
  List,
  ListItem,
  Spinner,
  Flex,
  Text,
  Image,
  Button,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { useGroupsSearch } from 'hooks/algoliaSearch';
import { useAuth } from 'hooks/auth';

const responseStyle = {
  common: {
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 'lg',
    boxShadow: 'md',
    padding: '4',
    _hover: {
      bgColor: 'black.200',
      boxShadow: 'lg',
    },
  },
};

function GroupsSearch() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const { results, loading, setQuery: setGroupsQuery } = useGroupsSearch();
  const { user, isLoading: authLoading } = useAuth();

  useEffect(() => {
    const userID = user?.id;
    console.log(userID);
  }, [user]);

  const handleSearch = () => {
    setGroupsQuery(query);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const filteredResults = results.filter((result) => {
    const members = result.members || [];
    const admins = result.admins || [];
    return !members.includes(user.id) && !admins.includes(user.id);
  });

  return (
    <Flex direction="column" align="center" mt="4">
      <Input
        type="text"
        textColor={'black'}
        borderColor={'#4267B2'}
        placeholder="Search for groups..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        maxW="600px"
        minW="600px"
        mb="2"
      />
      <Button m={'2'} bgColor="#4267B2" textColor="white" onClick={handleSearch}>
        Search
      </Button>
      {loading ? (
        <Spinner size="lg" />
      ) : (
        <List>
          {filteredResults.map((result) => (
            <ListItem key={result.objectID}>
              <Box
                {...responseStyle.common}
                minW={'600px'}
                maxW={'600px'}
                minH={'200px'}
                maxH={'200px'}
                mt={'4'}
                onClick={() => {
                  navigate(`/protected/groups/${result.id}`);
                }}
              >
                <Flex align="center" ml={'20px'}>
                  <Image src={result.profilePicture} alt={result.name} w="250px" h="150px" mr="4" />
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" color={'#4267B2'}>
                      {result.name}
                    </Text>
                    <Text fontSize="md" color={'#4267B2'}>
                      {result.description}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Flex>
  );
}

export default GroupsSearch;
