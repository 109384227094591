export function getRandomBibleVerse() {
    const bibleVerses = [
      "For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life. - John 3:16",
      "The Lord is my shepherd; I shall not want. - Psalm 23:1",
      "I can do all things through him who strengthens me. - Philippians 4:13",
      "The fear of the Lord is the beginning of knowledge, but fools despise wisdom and instruction. - Proverbs 1:7",
      "Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight. - Proverbs 3:5-6",
      "But the fruit of the Spirit is love, joy, peace, forbearance, kindness, goodness, faithfulness, gentleness and self-control. Against such things there is no law. - Galatians 5:22-23",
      "Therefore I tell you, whatever you ask for in prayer, believe that you have received it, and it will be yours. - Mark 11:24",
      "Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. - Philippians 4:6",
      "The Lord is close to the brokenhearted and saves those who are crushed in spirit. - Psalm 34:18",
      "He gives strength to the weary and increases the power of the weak. - Isaiah 40:29",
    ];
  
    // Generate a random index to select a verse
    const randomIndex = Math.floor(Math.random() * bibleVerses.length);
  
    return bibleVerses[randomIndex];
  }
  