import { useToast } from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  FieldValue,
} from "firebase/firestore";
import { db, storage } from "lib/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, GROUPS } from "lib/routes";




export function useCreateGroup() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  

  async function createGroup(user, groupName, groupDescription, profilePicture) {
    setLoading(true);
    const id = uuidv4();

    const imageRef = ref(storage, `postmedia/${profilePicture.name}`);
    const snapshot = await uploadBytesResumable(imageRef, profilePicture);
    const url = await getDownloadURL(snapshot.ref);
    const downloadURL = url;

    await setDoc(doc(db, "groups", id), {
      date: Date.now(),
      name: groupName, 
      description: groupDescription,
      members: [],
      admins: [user.id],
      uid: user.id,
      id,
      profilePicture: downloadURL,
    });

  
    toast({
      title: "Group successfully created!",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
    setLoading(false);
    navigate(GROUPS);
  }

  return { createGroup, isLoading };
}

export function useGroups() {
  const q = query(
    collection(db, "groups"),
    orderBy("date", "asc")
  );
  const [groups, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  return { groups, isLoading };
}

export function useReturnGroup(id) {
  const q = query(
    collection(db, "groups"),
    where("id", "==", id),
  );
  const [group, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  return { group, isLoading };
}


export function useAddToGroupViaUsername() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function addToGroupViaUsername(groupId, username) {
    setLoading(true);

    try {
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(
        usersCollectionRef,
        where("username", "==", username)
      );
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.size === 1) {
        const userDoc = userQuerySnapshot.docs[0];
        const userId = userDoc.id;

        const userDocRef = doc(db, "users", userId);
        const userDocData = (await getDoc(userDocRef)).data();

        if (!userDocData.pendingInvite || !userDocData.pendingInvite.includes(groupId)) {
          await updateDoc(userDocRef, {
            pendingInvite: arrayUnion(groupId), 
          });
        }

        toast({
          title: "Invite sent to user!",
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      
      } else {
        toast({
          title: "User not found.",
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error sending invite via username:", error);
      toast({
        title: "Error sending invite via username.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }

    setLoading(false);
  }

  return { addToGroupViaUsername, isLoading };
}


export function useAddToGroupViaEmail() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function addToGroupViaEmail(groupId, email) {
    setLoading(true);

    try {
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(usersCollectionRef, where("email", "==", email));
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.size === 1) {
        const userDoc = userQuerySnapshot.docs[0];
        const userId = userDoc.id;

        const userDocRef = doc(db, "users", userId);
        const userDocData = (await getDoc(userDocRef)).data();

        if (!userDocData.pendingInvite || !userDocData.pendingInvite.includes(groupId)) {
          await updateDoc(userDocRef, {
            pendingInvite: arrayUnion(groupId), 
          });
        }

        toast({
          title: "Invitation sent!",
          status: "success",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      
      } else {
        toast({
          title: "User not found.",
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error adding to group via email:", error);
      toast({
        title: "Error adding to group via email.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }

    setLoading(false);
  }

  return { addToGroupViaEmail, isLoading };
}

export function useAcceptGroupViaEmail() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function acceptGroupViaEmail(groupId, email) {
    setLoading(true);

    try {
      console.log(groupId, email);
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(usersCollectionRef, where("email", "==", email));
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.size === 1) {
        const userDoc = userQuerySnapshot.docs[0];
        const userId = userDoc.id;

        const userDocRef = doc(db, "users", userId);
        const userDocData = (await getDoc(userDocRef)).data();

        if (userDocData.pendingInvite.includes(groupId)) {

          const groupDocRef = doc(db, "groups", groupId);
          const groupDocData = (await getDoc(groupDocRef)).data();

          if (groupDocData && !groupDocData.members.includes(userId) && !groupDocData.admins.includes(userId)) {
            await updateDoc(groupDocRef, {
              members: arrayUnion(userId), 
            });
          }

          await updateDoc(userDocRef, {
            pendingInvite: arrayRemove(groupId), 
          });
          

          toast({
            title: "Invitation accepted!",
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        } else {
          toast({
            title: "You're already a member of this group.",
            status: "info",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
          navigate("/groups");
        }
      } else {
        toast({
          title: "User not found.",
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error accepting group invitation via email:", error);
      toast({
        title: "Error accepting group invitation via email.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }

    setLoading(false);
  }

  return { acceptGroupViaEmail, isLoading };
}

export function useDeclineGroupInvite() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function declineGroupInvite(groupId, email) {
    setLoading(true);

    try {
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(usersCollectionRef, where("email", "==", email));
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.size === 1) {
        const userDoc = userQuerySnapshot.docs[0];
        const userId = userDoc.id;
        const userDocRef = doc(db, "users", userId);
        const userDocData = (await getDoc(userDocRef)).data();

        if (userDocData.pendingInvite && userDocData.pendingInvite.includes(groupId)) {
          await updateDoc(userDocRef, {
            pendingInvite: arrayRemove(groupId), 
          });

          toast({
            title: "Invitation declined.",
            status: "info",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        } else {
          toast({
            title: "You were not invited to this group.",
            status: "info",
            isClosable: true,
            position: "top",
            duration: 5000,
          });

          navigate(DASHBOARD);
        }
      } else {
        toast({
          title: "User not found.",
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error declining group invitation via email:", error);
      toast({
        title: "Error declining group invitation via email.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }

    setLoading(false);
  }

  return { declineGroupInvite, isLoading };
}

export function useCreateGroupInvite() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  

  async function createGroupInvite(user, groupID, inviteId) {
    setLoading(true);

    await setDoc(doc(db, "groupInvites", inviteId), {
      id: inviteId,
      groupId: groupID,
      userId: user.id,

      
    });

  }

  return { createGroupInvite, isLoading };
}

export function useAcceptGroupLink() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  async function acceptGroupLink(groupId, email) {
    setLoading(true);

      console.log(groupId, email);
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(usersCollectionRef, where("email", "==", email));
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.size === 1) {
        const userDoc = userQuerySnapshot.docs[0];
        const userId = userDoc.id;

        const userDocRef = doc(db, "users", userId);
        const userDocData = (await getDoc(userDocRef)).data();

        const groupDocRef = doc(db, "groups", groupId);
        const groupDocData = (await getDoc(groupDocRef)).data();

        if (groupDocData && !groupDocData.members.includes(userId) && !groupDocData.admins.includes(userId)) {

            await updateDoc(groupDocRef, {
              members: arrayUnion(userId), 
            });

            toast({
              title: "Invitation accepted!",
              status: "success",
              isClosable: true,
              position: "top",
              duration: 5000,
            });
        }
        else{
            toast({
              title: "You're already a member of this group.",
              status: "failure",
              isClosable: true,
              position: "top",
              duration: 5000,
            });
          }

          await updateDoc(userDocRef, {
            pendingInvite: arrayRemove(groupId), 
          });
          

  


    setLoading(false);
  }

  
  } 
  return { acceptGroupLink, isLoading };

}

export function useSearchGroupsByName() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const groupsCollectionRef = collection(db, "groups");

      const searchTerms = searchQuery.toLowerCase().split(" ");

      const results = [];

      
      for (const term of searchTerms) {
        const nameQuery = query(groupsCollectionRef, where("name", ">=", term));
        const querySnapshot = await getDocs(nameQuery);

        querySnapshot.forEach((doc) => {
          const groupData = doc.data();
          if (!results.some((result) => result.id === groupData.id)) {
            results.push(groupData);
          }
        });
      }

      setSearchResults(results);
    } catch (error) {
      console.error("Error searching for groups: ", error);
    }
    setIsLoading(false);
  }

  return {
    searchQuery,
    setSearchQuery,
    searchResults,
    handleSearch,
    isLoading,
  };
}